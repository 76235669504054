import gsap from "gsap";
<template>
    <v-row style="display: flex; justify-content: center; min-height: calc(100vh - 64px); align-items: center">

        <v-col xs="12">
            <v-container>
                <v-row style="display: flex; justify-content: center">
                    <div class="customTitle gsapSuccess">
                        Account Activated
                    </div>
                </v-row>
                <v-row style="display: flex; justify-content: center; padding-top: 1em" >
                    <div style="max-width: 400px; text-align: center">
                        <p class="customSubTitle gsapSuccess">
                            Thanks for registering!
                        </p>
                        <p class="gsapSuccess">
                            You should receive an email in the next minute with a temporary password to log in to the
                            Portal. If you have any issues, please contact us at <span class="textLink">contactus@reactenergy.io</span>.
                        </p>

                    </div>

                </v-row>

            </v-container>
        </v-col>
    </v-row>
</template>

<script>
    import gsap from 'gsap';
    
    export default {
        mounted() {
            let tl = gsap.timeline({defaults: {duration: 1}});

            tl.from('.gsapSuccess', {opacity: 0, duration: 1, y: 50, delay: 1, stagger: 0.2})
        }
    }
</script>