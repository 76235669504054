<template>
    <v-container>
        <v-row class="customTitle" style="padding-bottom: 30px">
            Import Data
        </v-row>
<!--        <v-row>-->
<!--            <img src="../../../assets/images/Load-Curve-General.png"-->
<!--                 alt="React Energy - Interval Data Analysis"-->
<!--                 style="width: 100%; border-radius: 5px"-->

<!--            />-->
<!--        </v-row>-->
        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">
            General
        </v-row>
        <v-row class="customDescriptionText">
            The portal makes the process of uploading data simple. Currently, the portal supports data in either
            15-minute, 30-minute, or 60-minute intervals, and accepts data in both horizontal or vertical format.

        </v-row>
        
        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">
            Supported File Types for Uploading
        </v-row>
        <v-row class="customDescriptionText">
            <ul>
                <li>Microsoft Excel</li>
                <li>CSV</li>
                <li>Manual Copy and Paste</li>
            </ul>

        </v-row>
        <v-row class="customDescriptionText" style="margin-top: 1rem; display: flex; align-items: center" >
            <v-chip text-color="rgb(56, 142, 60, 1)" color="rgb(56, 142, 60, 0.15)">Coming Soon</v-chip> <span style="padding-left: 1rem">Support for Green Button files.</span>
        </v-row>
        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">
            Interval Ending Time
        </v-row>
        <v-row class="customDescriptionText">
            When uploading data, it is important to remember that the the datetime (or timestamp) associated with each
            interval should be the "interval ending" time. For example, for the period from 8:00AM to 8:15AM, the
            datetime should show 8:15, not 8:00.

        </v-row>
<!--        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">-->
<!--            Daylight Savings-->
<!--        </v-row>-->
<!--        <v-row class="customDescriptionText">-->
<!--            TODO: describe the daylight savings stuff-->

<!--        </v-row>-->
    </v-container>
</template>

<script>
    import SummaryLevels from './FilterOptions/SummaryLevels'
    import SeriesType from './FilterOptions/SeriesType'
    import Days from './FilterOptions/Days'
    import Months from './FilterOptions/Months'

    export default {
        components: {
            SummaryLevels,
            SeriesType,
            Days,
            Months
        }
    }
</script>