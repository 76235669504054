<template>
    <v-app-bar
            fixed
            color="white"
            elevate-on-scroll
            app
            style="z-index: 1000"
            

            
    >

            <v-container style="padding-bottom: 0; padding-top: 0; max-width: 1264px;">
                <v-row  style="display: flex; align-items: center">
                    <img src="../../../assets/images/ReactLogo_NoShadow.svg" alt="React Energy - Summary Chart" style="max-height: 30px; max-width: 30px"/>
                    
                    <div style="align-items: center; height: 100%">
                        <router-link to="/" style="text-decoration: none">
                            <div class="navbarTitle headline">React Energy</div>
                        </router-link>
                    </div>

                    <v-spacer></v-spacer>
<!--                    <div style="margin: auto">-->
<!--                        <router-link to="/product" style="text-decoration: none">-->

<!--                            <div class="navbarTab">-->
<!--                                Product-->
<!--                            </div>-->

<!--                        </router-link>-->
<!--                    </div>-->
                    <v-menu offset-y open-on-hover >
                        <template v-slot:activator="{ on }">
                            <router-link to="/product/overview" style="text-decoration: none">
                            <div class="navbarTab"
                                    v-on="on"
                            >
                                Product
                            </div>
                            </router-link>
                        </template>

                        <v-list style="box-shadow: none; width: 200px">
                            <router-link :to="item.link" style="text-decoration: none" v-for="(item, index) in productLinks">
                            <v-list-item
                                    
                                    :key="index"
                                    @click=""
                            >
                                
                                <v-list-item-title style="color: #000">{{item.label}}</v-list-item-title>
                                
                            </v-list-item>
                            </router-link>
                        </v-list>
                    </v-menu>

                    <div style="margin: auto">
                        <router-link to="/about" style="text-decoration: none">

                            <div class="navbarTab">
                                About
                            </div>

                        </router-link>
                    </div>

                    <div style="margin: auto">
                        <router-link to="/contactus" style="text-decoration: none">

                            <div class="navbarTab">
                                Contact
                            </div>

                        </router-link>
                    </div>
                    <div style="margin: auto">
                        <router-link to="/pricing" style="text-decoration: none">

                            <div class="navbarTab">
                                Pricing
                            </div>

                        </router-link>
                    </div>
                    <div style="margin: auto">
                        <router-link to="/free-trial" style="text-decoration: none">

                            <div class="navbarTab" style="color: #f57c00; font-weight: 500">
                                Free Trial
                            </div>

                        </router-link>
                    </div>
                    <div style="margin: auto">
                        <a class="navbarTab" href="https://portal.reactenergy.io/" style="text-decoration: none; color: #757575">
                            Login
                        </a>
                    </div>

                </v-row>
            </v-container>

            


    </v-app-bar>
</template>

<script>
    export default {
        data: () => ({
            links: [
                {
                    label: 'Product',
                    link: '/product/overview',
                    
                }
            ],
            links1: [
                'Product',
                'About Us',
                'Contact',
                'Pricing',
                'Free Trial',
                'Login',
            ],
            productLinks: [
                {
                    label: 'Overview',
                    link: '/product/overview',
                },
                {
                    label: 'Charts',
                    link: '/product/load-curve',
                },
                {
                    label: 'Weather',
                    link: '/product/weather',
                },
                
            ]
        }),
    }
</script>