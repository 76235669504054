<template>
    <v-container>
        <v-row class="customTitle" style="padding-bottom: 30px">
            Degree Days
        </v-row>
        <v-row>
            <v-container style="display: flex; justify-items: center">
                <div style="margin: auto" class="homeSection1Image">
                    <img src="../../../assets/images/screenshots/DegreeDayScreenshot.png"
                         alt="React Energy - Interval Data Analysis"
                         style="width: 100%"
                         class="productImage"
                    />
                </div>
            </v-container>
        </v-row>
        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">
            General
        </v-row>
        <v-row>
            Description coming soon.
        </v-row>
<!--        <v-row class="customDescriptionText">-->
<!--            The React Energy Portal automatically calculates both Heating Degree Days (HDD) and Cooling Degree Days-->
<!--            (CDD) from the weather data it pulls in based on the meter's zip (postal) code. The default base-->
<!--            temperatures for both HDD and CDD are 65°F, but can be eaily edited to any value you prefer.-->
<!--        </v-row>-->
<!--        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">-->
<!--            What are Degree Days?-->
<!--        </v-row>-->
<!--        <v-row class="customDescriptionText">-->
<!--            <p>-->
<!--                Degree days are a simplified form of weather data. They are commonly used to analyze and model the-->
<!--                relationship with temperature and energy consumption.-->
<!--            </p>-->
<!--            <p>-->

<!--                One of the most common applications of degree days is the weather normalization of energy consumption,-->
<!--                which anables for a like-for-like comparison of energy consumption from different periods, and even-->
<!--                places. This comparison is typically done through regression analysis, with the goal of assessing energy-->
<!--                performance or identifying signs of waste.-->

<!--            </p>-->
<!--        </v-row>-->
<!--        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">-->
<!--            How does React calculate Degree Days?-->
<!--        </v-row>-->
<!--        <v-row class="customDescriptionText">-->
<!--            <p>-->
<!--                Instead of simply using daily summary statistics like max, average, and minimum temperatures, React-->
<!--                calculates degree days using the Integration Method. -->
<!--            </p>-->
<!--            <p>-->
<!--                By using the most granular data available, the Integration Method accurately accounts for temperature variations within each day. To understand why -->
<!--                -->

<!--            </p>-->
<!--        </v-row>-->
<!--        <v-row>-->
<!--            <highcharts :options="chartOptions"></highcharts>-->
<!--        </v-row>-->
<!--        <v-row>-->
<!--            <highcharts :options="chartOptions2"></highcharts>-->
<!--        </v-row>-->

    </v-container>
</template>

<script>
    import SummaryLevels from './FilterOptions/SummaryLevels'
    import SeriesType from './FilterOptions/SeriesType'
    import Days from './FilterOptions/Days'
    import Months from './FilterOptions/Months'
    import {Chart} from 'highcharts-vue'
    
    export default {
        components: {
            SummaryLevels,
            SeriesType,
            Days,
            Months,
            highcharts: Chart
        },
        data() {
            return {
                chartOptions: {
                    chart: {
                        style: {
                            fontFamily: 'Eina,system-ui,-apple-system,BlinkMacSystemFont,"Helvetica Neue",Helvetica,Arial,sans-serif;'
                        },
                       
                    },
                    title: {
                        text: 'Degree Days'
                    },
                    subtitle: {
                        text: 'CDD Base: 65°F | HDD Base: 65°F'
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        type: 'area',
                        data: [63,63,63,63,62,62,62,62,61,61,62,64,66,68,70,72,74,75,76,75,74,72,69,67,65], // sample data,
                        threshold: 65,
                        negativeColor: 'rgba(255, 0, 0, 1)',
                        negativeFillColor: {
                            linearGradient: {
                                x1: 0,
                                y1: 1,
                                x2: 0,
                                y2: 0
                            },
                            stops: [
                                [0, '#ef5350'],
                                [1, '#ffebee']
                            ]
                        },
                        fillColor: {
                            linearGradient: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 1
                            },
                            stops: [
                                [0, 'rgb(124, 181, 236)'],
                                [1, 'rgb(124, 181, 236, 0)']
                            ]
                        },
                    }]
                },
                chartOptions2: {
                    chart: {
                        style: {
                            fontFamily: 'Eina,system-ui,-apple-system,BlinkMacSystemFont,"Helvetica Neue",Helvetica,Arial,sans-serif;'
                        },

                    },
                    title: {
                        text: 'Degree Days'
                    },
                    subtitle: {
                        text: 'CDD Base: 70°F | HDD Base: 65°F'
                    },
                    credits: {
                        enabled: false
                    },
                    yAxis: {
                      min: 60  
                    },
                    series: [{
                        type: 'area',
                        data: [63,63,63,63,62,62,62,62,61,61,62,64,66,68,70,72,74,75,76,75,74,72,69,67,65], // sample data,
                        threshold: 65,
                        zones: [
                            {
                                value: 65,
                                color: 'rgba(255, 0, 0, 1)',
                                fillColor: {
                                    linearGradient: {
                                        x1: 0,
                                        y1: 1,
                                        x2: 0,
                                        y2: 0
                                    },
                                    stops: [
                                        [0, '#ef5350'],
                                        [1, '#ffebee']
                                    ]
                                },
                            },
                            {
                                value: 70,
                                color: '#9e9e9e',
                                fillColor: '#f5f5f5'
                                // fillColor: {
                                //     linearGradient: {
                                //         x1: 0,
                                //         y1: 0,
                                //         x2: 0,
                                //         y2: 0
                                //     },
                                //     stops: [
                                //         [0, '#fff'],
                                //         [1, '#fff']
                                //     ]
                                // },
                            },
                            {
                                fillColor: {
                                    linearGradient: {
                                        x1: 0,
                                        y1: 0,
                                        x2: 0,
                                        y2: 1
                                    },
                                    stops: [
                                        [0, 'rgb(124, 181, 236)'],
                                        [1, 'rgb(124, 181, 236, 0)']
                                    ]
                                },
                            }
                        ],
                        
                    }]
                }
            }
        }
    }
</script>