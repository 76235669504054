<template>

        <v-container style="max-width: 1264px;">
        <v-container >
            <v-row class="customTitle text-center" justify="center" style="padding-top: 20px">

                The right price for you
            </v-row>
        </v-container>
        
        <v-container>
            <v-row style="display: flex; justify-content: center">


                <v-col lg="5" md="6" sm="9" v-for="product in products">
                    <v-card>

                        <v-row style="padding: 10px 30px">
                            <v-row style="margin: 0 10px">
                                <v-col lg="9" style="padding: 0">
                                    <v-row>
                                        <div style="font-size: 1.5em; font-weight: 600; line-height: 1.25em">
                                            {{product.title}}
                                        </div>
                                    </v-row>
<!--                                    <v-row class="customSubTitle" style="margin-top: 0; font-size: 1.25em">-->
<!--                                        This is the subtitle-->
<!--                                    </v-row>-->
                                </v-col>
                                <v-col lg="2" style="padding: 0; justify-content: center; align-items: center; height: 100%;" >
                                    <v-row style="height: 100%; display: flex; align-items: center; justify-content: flex-end" >
                                        <i :class="product.icon" style="font-size: 2em"></i>
                                    </v-row>
                                    
                                </v-col>
                                
                            </v-row>

                        </v-row>


                        <v-divider/>
                        <v-card-text>
                            <div class="content">
                                <div style="padding: 0 20px">
                                    <div style="font-size: 3rem; font-weight: 800;  color: black; padding: 10px 0">
                                        ${{product.monthPrice}}
                                    </div>
                                    <div style="margin-top: 5px; padding-left: 5PX">
                                        Per Month / Per User
                                    </div>

                                </div>

                                <v-row v-for="attribute in attributes" style="padding: 0 20px">
                                    <v-container>
                                        <v-row>
                                            <i class="fa fa-check-circle"
                                               style="color: #4CAF50; font-size: 1.5em; width: 20px"></i>
                                            <span style="padding-left: 10px; font-size: 1.5em; color: #000; font-weight: 600">{{attribute.label}}</span>
                                        </v-row>
                                        <v-row>
                                            <div style="padding-left: 30px;">{{attribute.description}}</div>
                                        </v-row>


                                    </v-container>
                                    <v-divider></v-divider>
                                </v-row>


                            </div>
                            <v-row style="padding-top: 20px" justify="center">
                                <router-link to="/free-trial" style="text-decoration: none; width: 100%; display: flex; justify-content: center">
                                    <v-btn large rounded style="background-color: #f57c00; color:  white; width: 80%"><div class="customButton">Free Trial</div>
                                    </v-btn>
                                </router-link>
                            </v-row>
                        </v-card-text>

                    </v-card>
                </v-col>


            </v-row>

        </v-container>

        </v-container>
</template>

<style>
    .personIcon {
        background: -webkit-linear-gradient(#29B6F6, #0288D1);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
</style>

<script>
    import Axios from "axios";

    export default {
        name: 'Pricing',
        data() {
            return {
                activePlans: null,
                attributes: [
                    {
                        label: 'Unlimited Projects & Meters',
                        description: 'Upload and store as much interval data as you can.',
                        icon: 'fas fa-tachometer-alt'
                    },
                    {
                        label: 'Support and Training',
                        description: 'In case you have any questions, you can email us at any time.',
                        icon: 'fas fa-phone-alt'
                    },
                    {
                        label: 'Access to Weather Data',
                        description: 'Take advantage of our APIs that automatically pull in temperature data',
                        icon: 'fas fa-sun'
                    },
                    {
                        label: 'No Installation Required',
                        description: 'Our cloud based platform does not require any installation on your computer.',
                        icon: 'fas fa-cloud'
                    },
                ],
                products: [
                    {
                        label: 'Basic',
                        title: 'For Professionals',
                        subtitle: 'Subtitle for professionals',
                        icon: 'fa fa-user personIcon',
                        monthPrice: 39.99,
                        
                    },
                    {
                        label: 'Basic',
                        title: 'For Academics',
                        subtitle: 'Subtitle for professionals',
                        icon: 'fa fa-user-graduate personIcon',
                        monthPrice: 29.99,
                        
                    },
                ]
            }
        },
        created: function () {
            this.getActivePlans()
        },
        methods: {
            getActivePlans: function () {
                Axios.post('/api/account/GetActiveProductPlans', {}).then(response => {
                    this.activePlans = response

                });
            },
        }
    }
</script>