<template>
    <v-container>
        <v-row class="customTitle" style="padding-bottom: 30px">
            Summary Chart
        </v-row>
        <v-row>
<!--            <img src="../../../assets/images/screenshots/SummaryChartScreenshot.png"-->
<!--                 alt="React Energy - Interval Data Analysis"-->
<!--                 class="productImage"-->

<!--            />-->
            <v-container style="display: flex; justify-items: center">
                <div style="margin: auto" class="homeSection1Image">
                    <img src="../../../assets/images/screenshots/SummaryChartScreenshot.png"
                         alt="React Energy - Interval Data Analysis"
                         style="width: 100%"
                         class="productImage"
                    />
                </div>
            </v-container>
        </v-row>
        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">
            General
        </v-row>
        <v-row class="customDescriptionText">
            The summary chart tool creates interactive column charts from interval data. One of the most common energy
            data points,
            total energy consumption (kWh) per month, can be derived from this chart.

        </v-row>

        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">
            X-Axis Zoom
        </v-row>
        <v-row class="customDescriptionText">
            The summary charts gives you the ability to filter by a summary level of "Day", which has the potential to
            return hundreds of data points. Simply click and drag over the portion of the x-axis you wish to focus on to
            get a closer look.

        </v-row>

        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">
            Data Types
        </v-row>
        <v-row class="customDescriptionText">
            <ul>
                <li>Minimum kilowatts (kW)</li>
                <li>Average kilowatts (kW)</li>
                <li>Maximum kilowatts (kW)</li>
                <li>Minimum kilowatt-hours (kWh)</li>
                <li>Average kilowatt-hours (kWh)</li>
                <li>Maximum kilowatt-hours (kWh)</li>
                <li>Total kilowatt-hours (kWh)</li>
                <li>Peak demand</li>
            </ul>

        </v-row>
        <SummaryLevels/>
        <Days/>
        <Months/>


    </v-container>
</template>


<script>
    import SummaryLevels from './FilterOptions/SummaryLevels'
    import SeriesType from './FilterOptions/SeriesType'
    import Days from './FilterOptions/Days'
    import Months from './FilterOptions/Months'

    export default {
        components: {
            SummaryLevels,
            SeriesType,
            Days,
            Months
        }
    }
</script>