<template>
    <v-container>
        <v-row class="customTitle" style="padding-bottom: 1rem">
            React Energy Portal
        </v-row>

        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">
            Overview
        </v-row>
        <v-row class="customDescriptionText">
            <p>
                Over the past decade, the "smart meter revolution" has transformed the energy space. As energy
                professionals, we now have significantly more data to help us improve and optimize the
                energy space. However, to do that we need to have tools in place to quickly maximize the benefit of
                having that data on hand, and that is where React comes in to play.
            </p>
            <p>
                If you use interval data in your work as an energy professional, the React Energy Portal is the tool for
                you. The React Energy Portal simplifies the process of storing and analyzing interval data, allowing you
                to spend less time with the data, and more time solving problems.
            </p>


        </v-row>
        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">
            Product Goals
        </v-row>
        <v-row class="customDescriptionText">
            <ol>
                <li><span style="font-weight: 600">Maximize Insights, Minimize Time: </span> Interval data is packed
                    with useful
                    information. However, discovering everything
                    the data is trying to tell you is a cumbersome process. Our charts are designed to help you find the
                    relevant information in seconds, not hours.
                </li>
                <li><span style="font-weight: 600">Optimize Organization: </span> Often times as energy professionals,
                    we deal deal with hundreds of interval data files. Going back through data means opening countless,
                    large excel files that not only take long to open and move through, but also bog dog your computer.
                    In React, you can move through all of your meter data sets in a matter of seconds.
                </li>
                <li><span style="font-weight: 600">Communicate Findings and Ideas Effectively: </span>The insights gained from interval
                    data often need to not only be understood
                    by the energy professional, but also communicated in a way a customer can understand. The ability to
                    dynamically query and move through the data make this communcation process not only simpler, but
                    enjoyable.
                </li>
            </ol>
        </v-row>
        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">
            Portal Layout
        </v-row>
        <v-row class="customDescriptionText">
            <p>
                At the highest level, you start by creating a project. Projects can contain an unlimited amount of
                meters, making it suitable for projects with tens or hundreds of meters, such as a school district or
                city.
            </p>
            <p>
                When creating a meter, we require a zip (postal) code to be entered so that we can pull in your
                weather data. You can learn more about our weather data
                <router-link to="/product/weather" style="text-decoration: none">
                    <span class="textLink">here</span>
                </router-link>.
            </p>
        </v-row>
<!--        <v-row>-->
<!--            <img src="../../../assets/images/Load-Curve-General.png"-->
<!--                 alt="React Energy - Interval Data Analysis"-->
<!--                 style="width: 100%; border-radius: 5px"-->

<!--            />-->
<!--        </v-row>-->
        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">
            Chart Types
        </v-row>
        <v-row class="customDescriptionText">
            <p>
                The Portal currently provides (7) different charting options to visualize your data. Click on one to learn more.
            </p>
            <ul>
                <li v-for="chart in charts">
                    <router-link :to="chart.link" style="text-decoration: none">
                        <span class="textLink">
                            {{chart.title}}
                        </span>
                        
                    </router-link>
                </li>

            </ul>

        </v-row>
        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">
            Interval Ending Time
        </v-row>
        <v-row class="customDescriptionText">
            When uploading data, it is important to remember that the the datetime (or timestamp) associated with each
            interval should be the "interval ending" time. For example, for the period from 8:00AM to 8:15AM, the
            datetime should show 8:15, not 8:00.

        </v-row>
<!--        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">-->
<!--            Daylight Savings-->
<!--        </v-row>-->
<!--        <v-row class="customDescriptionText">-->
<!--            TODO: describe the daylight savings stuff-->

<!--        </v-row>-->
    </v-container>
</template>

<script>
    import SummaryLevels from './FilterOptions/SummaryLevels'
    import SeriesType from './FilterOptions/SeriesType'
    import Days from './FilterOptions/Days'
    import Months from './FilterOptions/Months'
    import charts from "../charts";

    export default {
        components: {
            SummaryLevels,
            SeriesType,
            Days,
            Months
        },
        data() {
            return {
                charts,
            }
        }
    }
</script>