<template>
    <v-container>
        <v-row class="customTitle" style="padding-bottom: 30px">
            Export Data
        </v-row>
<!--        <v-row>-->
<!--            <img src="../../../assets/images/Load-Curve-General.png"-->
<!--                 alt="React Energy - Interval Data Analysis"-->
<!--                 style="width: 100%; border-radius: 5px"-->

<!--            />-->
<!--        </v-row>-->
        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">
            General
        </v-row>
        <v-row class="customDescriptionText">
            Each chart gives you the ability to export filtered data by clicking the "Table" tab.

        </v-row>

<!--        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">-->
<!--            Data Types-->
<!--        </v-row>-->
<!--        <v-row class="customDescriptionText">-->
<!--            <ul>-->
<!--                <li>Kilowatts (kW)</li>-->
<!--                <li>Kilowatt-Hours (kWh)</li>-->
<!--            </ul>-->

<!--        </v-row>-->
<!--        <SummaryLevels/>-->
<!--        <SeriesType />-->
<!--        <Days/>-->
<!--        <Months/>-->
        
        

    </v-container>
</template>


<script>
    import SummaryLevels from './FilterOptions/SummaryLevels'
    import SeriesType from './FilterOptions/SeriesType'
    import Days from './FilterOptions/Days'
    import Months from './FilterOptions/Months'
    
    export default {
        components: {
            SummaryLevels,
            SeriesType,
            Days,
            Months
        }
    }
</script>