<template>
    <v-container style="margin: 10px 20px">
        <v-icon color="white" v-on:click="toggleSlideout" style="font-size: 35px; padding-bottom: 10px">fas fa-times</v-icon>
        <v-row v-for="link in links" style="display: flex; justify-items: flex-end" >
            <div v-on:click="toggleSlideout" class="display-1" style="padding: 5px 0">
                <router-link :to="link.link" style="text-decoration: none; color: white">
                    {{link.label}}
                </router-link>
            </div>
        </v-row>
        

    </v-container>

</template>

<script>
    import Particles from "../Particles/Particles";

    export default {
        props: ['toggleSlideout'],
        data() {
            return {
                links: [
                    {
                        label: 'Home',
                        link: '/'
                    },
                    {
                        label: 'Product',
                        link: '/product/overview'
                    },
                    {
                        label: 'About',
                        link: '/about'
                    },
                    {
                        label: 'Contact',
                        link: '/contactus'
                    },
                    {
                        label: 'Pricing',
                        link: '/pricing'
                    },
                    {
                        label: 'Free Trial',
                        link: '/free-trial'
                    },
                    
                ]
            }
        },
        components: {
            'particles-example': Particles
        },
    }
</script>