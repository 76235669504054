<template>
    <v-container>
        <v-row class="customTitle" style="padding-bottom: 30px">
            Scatter Plot
        </v-row>
        <v-row>
<!--            <img src="../../../assets/images/screenshots/ScatterplotScreenshot.png"-->
<!--                 alt="React Energy - Interval Data Analysis"-->
<!--                 class="productImage"-->

<!--            />-->
            <v-container style="display: flex; justify-items: center">
                <div style="margin: auto" class="homeSection1Image">
                    <img src="../../../assets/images/screenshots/ScatterplotScreenshot.png"
                         alt="React Energy - Interval Data Analysis"
                         style="width: 100%"
                         class="productImage"
                    />
                </div>
            </v-container>
        </v-row>
        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">
            General
        </v-row>
        <v-row class="customDescriptionText">
            The scatter plot tool plots every data point on to one single chart, no aggregations necessary. 

        </v-row>

        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">
            Data Types
        </v-row>
        <v-row class="customDescriptionText">
            <ul>
                <li>Kilowatts (kW)</li>
                <li>Kilowatt-hours (kWh)</li>
            </ul>

        </v-row>
    </v-container>
</template>

<script>
    import SummaryLevels from './FilterOptions/SummaryLevels'
    import SeriesType from './FilterOptions/SeriesType'
    import Days from './FilterOptions/Days'
    import Months from './FilterOptions/Months'
    
    export default {
        components: {
            SummaryLevels,
            SeriesType,
            Days,
            Months
        }
    }
</script>