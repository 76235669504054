<template>
    <div v-if="chartType === 'load-curve'">
        <img src="../../../../assets/images/CustomPNGIcons/ChartIcons/LoadCurveIconColor.png" alt="React Energy - Summary Chart" style="max-height: 48px; max-width: 48px"/>
    </div>
    <div v-else-if="chartType === 'summary-chart'">
        <img src="../../../../assets/images/CustomPNGIcons/ChartIcons/SummaryChartIconColor.png" alt="React Energy - Summary Chart" style="max-height: 48px; max-width: 48px"/>
    </div>
    <div v-else-if="chartType === 'heat-map'">
        <img src="../../../../assets/images/CustomPNGIcons/ChartIcons/HeatMapIconColor.png" alt="React Energy - Summary Chart" style="max-height: 48px; max-width: 48px"/>
    </div>
    <div v-else-if="chartType === 'time-series'">
        <img src="../../../../assets/images/CustomPNGIcons/analyzeChartSmall2.png" alt="React Energy - Summary Chart" style="max-height: 48px; max-width: 48px"/>
    </div>
    <div v-else-if="chartType === 'calendar-chart'">
        <img src="../../../../assets/images/CustomPNGIcons/ChartIcons/CalendarChartIconColor.png" alt="React Energy - Summary Chart" style="max-height: 48px; max-width: 48px"/>
    </div>
    <div v-else-if="chartType === 'demand-duration'">
        <img src="../../../../assets/images/CustomPNGIcons/ChartIcons/DemandDurationIconColor.png" alt="React Energy - Summary Chart" style="max-height: 48px; max-width: 48px"/>
    </div>
    <div v-else-if="chartType === 'scatter-plot'">
        <img src="../../../../assets/images/CustomPNGIcons/ChartIcons/ScatterPlotIconColor.png" alt="React Energy - Summary Chart" style="max-height: 48px; max-width: 48px"/>
    </div>
    <div v-else-if="chartType === 'surface-plot'">
        <img src="../../../../assets/images/CustomPNGIcons/ChartIcons/3dIconColor.png" alt="React Energy - Summary Chart" style="max-height: 48px; max-width: 48px"/>
    </div>
    
</template>

<script>
    export default {
        props: ['chartType']
    }
</script>