<template>
    <div class="aboutMain" id="contactUs" style="min-height: calc(100vh)">
        <v-container style="max-width: 1264px;">
            <v-row>
                <v-col cols="12" lg="12" align="center" justify="center">
                    <div style="max-width: 300px">
                        <div class="display-1">Contact Us</div>
                        <div class="orangeBanner"></div>
                    </div>

                </v-col>
            </v-row>
            <div >
                <v-row>
                    <v-col cols="12" md="6" align="center" justify="center">
                        <v-container style="max-width: 400px; text-align: start">


                            <div style="color: #616161; font-size: 1.25em; font-weight: bold; display: inline-block">Location</div>

                            <br/>
                            <div style="font-size: 1.25em; color: #757575; padding: 10px">
                                4455 Bowie Blvd<br/>
                                Suite 114 #900<br/>
                                Fort Worth, TX
                            </div>
                            <div style="color: #616161; font-size: 1.25em; font-weight: bold; display: inline-block">Email</div>

                            <br/>
                            <div style="font-size: 1.25em; color: #757575; padding: 10px">
                                ContactUs@reactenergy.io
                            </div>
                            <div style="color: #616161; font-size: 1.25em; font-weight: bold; display: inline-block">Phone</div>

                            <br/>
                            <div style="font-size: 1.25em; color: #757575; padding: 10px">
                                281-726-3820
                            </div>
                            <div style="color: #42a5f5; font-size: 1.25em; font-weight: bold; display: inline-block; padding-top: 10px">Free Trial or Demo</div>

                            <br/>
                            <div style="font-size: 1em; color: #757575; padding: 10px">
                                If you'd like a free trial or a live demo, please either submit this contact form or shoot us an email and we will get you set up!
                            </div>
                        </v-container>

                    </v-col>
                    <v-col cols="12" md="6" align="center" justify="center">
                        <v-container>


                            <div style="text-align: start; font-size: 2em; padding-bottom: 10px; color: #757575">
                                Contact Form
                            </div>

                            <v-text-field
                                    label="*Name"
                                    solo
                                    outlined
                                    color="#42a5f5"
                                    v-model="contactUs.name">
                            </v-text-field>

                            <v-text-field
                                    label="*Email"
                                    outlined
                                    solo
                                    color="#42a5f5"
                                    v-model="contactUs.email">
                            </v-text-field>

                            <v-text-field
                                    label="Phone Number"
                                    outlined
                                    v-model="contactUs.phone"
                                    mask="###.###.###-##"
                                    color="#42a5f5"
                                    solo>
                            </v-text-field>

                            <v-textarea
                                    label="*Message"
                                    outlined
                                    color="#42a5f5"
                                    v-model="contactUs.message"
                                    solo>
                            </v-textarea>
                            <v-btn large rounded style="background-color: #f57c00; color:  white; width: 140px" v-on:click="submitContactUs"><div class="customButton">Submit</div></v-btn>

                        </v-container>
                        <v-snackbar
                                v-model="contactUsSnackbar.open"
                                top
                                v-bind:color="contactUsSnackbar.color"
                        >
                            {{ contactUsSnackbar.message }}
                            <v-btn
                                    color="pink"
                                    text
                                    v-on:click="contactUsSnackbar.open = false"
                            >
                                <i class="fa fa-times" style="color: #ffffff; font-size: 2em"></i>
                            </v-btn>
                        </v-snackbar>
                    </v-col>
                </v-row>
            </div>

        </v-container>

    </div>
</template>

<script>
    import Axios from "axios";
    import gsap from 'gsap'
    import {CSSRulePlugin} from 'gsap/CSSRulePlugin'

    export default {
        name: 'ContactUs',
        // mounted: function () {
        //     // gsap.from('.homeSection1Title', {opacity: 0, duration: 1, y: 50, delay: 1 });
        //     // gsap.from('#homeSection1Description', {opacity: 0, duration: 1, y: -50 });
        //     // gsap.to('.orangeBanner', {width: '70%', duration: 2});
        //
        //     let tl = gsap.timeline({defaults: {duration: 1}});
        //
        //     tl.to('#contactUs', {opacity:1 , duration: 1, delay: 1})
        //         // .from('#homeSection1Description', {opacity: 0, duration: 1, y: 50}, '-=0.5')
        //         // .from('.homeButtons', {opacity: 0, duration: 1, y: 50}, '-=0.5')
        //         // .from('.homeSection1Image', {opacity: 0, scale: 0}, '-=1.0')
        //         // .to('.orangeBanner', {width: '70%', duration: 2})
        //
        // },
        data(){
            return{
                contactUs: {
                    name: null,
                    email: null,
                    phone: null,
                    message: null
                },
                contactUsSnackbar: {
                    open: false,
                    message: null,
                    color: null,
                },
            }
        },
        methods:{
            submitContactUs: function() {
                let self = this;
                if (this.contactUs.email && this.contactUs.message && this.contactUs.name) {

                    

                    //submit contactus form using axios
                    Axios.post('/api/home/SendContactUsEmail', {
                        name: this.contactUs.name,
                        email: this.contactUs.email,
                        phone: this.contactUs.phone,
                        message: this.contactUs.message
                    }).then( function(){
                        self.contactUsSnackbar = {
                            message: 'Thanks for reaching out! Look for an email from us shortly.',
                            open: true,
                            color: '#4caf50'
                        };
                        self.contactUs.name = null;
                        self.contactUs.email = null;
                        self.contactUs.phone = null;
                        self.contactUs.message = null;
                    });
                } else {
                    self.contactUsSnackbar = {
                        message: 'Please supply your name, email, and a message before submitting',
                        open: true,
                        color: '#f44336'
                    }
                }

            },
        }
    }
</script>