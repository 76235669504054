<template>
    <v-app-bar
            style="background-color: white"
            elevate-on-scroll
            scroll-target="#scrolling-techniques-7"
            height="60"
            class="hidden-md-and-up"
    >
        <v-container>
            <v-row justify="center" align="center" style="max-width: 800px">

                <img
                        src="../../../assets/images/ReactLogo_NoShadow.svg"
                        alt="React Energy - Interval Data Analysis Software"
                        style="max-height: 35px" />
      
                
                
<!--                <div><button class="toggle-button">☰</button>Panel</div>-->
                <div style="align-items: center; height: 100%">
                    <router-link to="/" style="text-decoration: none">
                        <div class="navbarTitle headline" >React Energy</div>
                    </router-link>
                </div>
                
                <v-spacer></v-spacer>
                <v-icon color="#BDBDBD" v-on:click="toggleSlideout">fas fa-bars</v-icon>
            </v-row>
        </v-container>


    </v-app-bar>
</template>

<script>
    export default {
        props: ['toggleSlideout'],

    }
</script>