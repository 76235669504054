<template>
    <v-container>
        <v-row class="customTitle" style="padding-bottom: 30px">
            Weather
        </v-row>
        <v-row>
<!--            <img src="../../../assets/images/screenshots/RawWeatherScreenshot.png"-->
<!--                 alt="React Energy - Interval Data Analysis"-->
<!--                 class="productImage"-->

<!--            />-->
            <v-container style="display: flex; justify-items: center">
                <div style="margin: auto" class="homeSection1Image">
                    <img src="../../../assets/images/screenshots/RawWeatherScreenshot.png"
                         alt="React Energy - Interval Data Analysis"
                         style="width: 100%"
                         class="productImage"
                    />
                </div>
            </v-container>
        </v-row>
        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">
            General
        </v-row>
        <v-row class="customDescriptionText">
            <p>
                Interval data becomes even more powerful with the addition of weather data. The Portal pulls weather
                data for each meter based on the zip (postal) code provided for that meter.
            </p>
            <p>
                <span style="font-weight: 600; padding-right: 3px">Where do we get our weather data? </span> The Portal
                utilizes an API with Meteostat to pull in the most granular and accurate data possible.
            </p>
        </v-row>
        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">
            Weather Data Points
        </v-row>
        <v-row class="customDescriptionText">
            <ul>
                <li>Temperature</li>
                <li>Humidity</li>
                <li>Dewpoint</li>
                <li>Precipitation</li>
                <li>Wind Speed</li>
                <li>Wind Direction</li>
                <li>Pressure</li>
            </ul>

        </v-row>

        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">
            Charts with Weather
        </v-row>
        <v-row class="customDescriptionText">
            <ul>
                <li>Time Series</li>
                <li>Calendar Chart</li>
                <li>Degree Days</li>
                <li>Raw Weather Data</li>
            </ul>

        </v-row>
        <!--        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">-->
        <!--            Missing Data-->
        <!--        </v-row>-->
        <!--        <v-row class="customDescriptionText">-->
        <!--            <p>-->
        <!--                Temperature data isn't perfect, often times having missing intervals for hours or even days. The Portal -->
        <!--            </p>-->
        <!--        </v-row>-->
    </v-container>
</template>

<script>
    import SummaryLevels from './FilterOptions/SummaryLevels'
    import SeriesType from './FilterOptions/SeriesType'
    import Days from './FilterOptions/Days'
    import Months from './FilterOptions/Months'

    export default {
        components: {
            SummaryLevels,
            SeriesType,
            Days,
            Months
        }
    }
</script>