<template>
    <v-container>
        <v-row class="customTitle" style="padding-bottom: 30px">
            Heat Map
        </v-row>
        <v-row>
            <v-container style="display: flex; justify-items: center">
                <div style="margin: auto" class="homeSection1Image">
                    <img src="../../../assets/images/screenshots/HeatmapScreenshot.png"
                         alt="React Energy - Interval Data Analysis"
                         style="width: 100%"
                         class="productImage"
                    />
                </div>
            </v-container>
        </v-row>
        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">
            General
        </v-row>
        <v-row class="customDescriptionText">
            Heat maps color code energy demand in a tabular format based on the meter's minimum and maximum demand,
            making it easy to spot seasonal trends. For heat maps, all data is aggregated up to an average hourly value
            for rendering purposes.

        </v-row>
        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">
            Views
        </v-row>
        <v-row class="customDescriptionText">
            <ul>
                <li><span class="listTitle">Week: </span>Chart x-axis stretches from Sunday morning to Saturday night,
                    making each row an entire week.
                </li>
                <li><span class="listTitle">Day: </span>Chart x-axis stretches from midnight to midnight, making each row
                    a single day.
                </li>
            </ul>


        </v-row>
        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">
            Data Types
        </v-row>
        <v-row class="customDescriptionText">
            <ul>
                <li>Kilowatts (kW)</li>
                <li>Kilowatt-hours (kWh)</li>
            </ul>

        </v-row>


    </v-container>
</template>


<script>
    import SummaryLevels from './FilterOptions/SummaryLevels'
    import SeriesType from './FilterOptions/SeriesType'
    import Days from './FilterOptions/Days'
    import Months from './FilterOptions/Months'

    export default {
        components: {
            SummaryLevels,
            SeriesType,
            Days,
            Months
        }
    }
</script>