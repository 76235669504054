<template>
    <v-container style="max-width: 200px">
        <v-row class="customTitle" style="font-size: 2em">
            Content
        </v-row>
<ProductMenu :selectedComponent="selectedComponent"/>
<!--        <router-link v-for="chart in charts" :to="chart.link" style="text-decoration: none">-->
<!--            <v-row style="padding: 5px 0" align="center">-->
<!--                <div style="display: flex; width: 30px; justify-content: center">-->
<!--                <i :class="[chart.icon, (selected === chart.name) ? 'selectedIcon' : 'unselectedIcon']"-->
<!--                   style="font-size: 1.3em"></i>-->
<!--                </div>-->
<!--                <div :class="[selected === chart.name ? 'chartMenuLinkSelected' : 'chartMenuLink']"-->
<!--                     style="padding-left: 10px">-->
<!--                    {{chart.title}}-->
<!--                </div>-->
<!--            </v-row>-->
<!--        </router-link>-->

<!--            <v-divider style="width: 100%; margin: 5px 0"></v-divider>-->

<!--        -->
<!--        <router-link v-for="item in weatherLinks" :to="item.link" style="text-decoration: none">-->
<!--            <v-row style="padding: 5px 0" align="center">-->
<!--                <div style="display: flex; width: 30px; justify-content: center">-->
<!--                    <i :class="[item.icon, (selected === item.name) ? 'selectedIcon' : 'unselectedIcon']"-->
<!--                       style="font-size: 1.3em"></i>-->
<!--                </div>-->
<!--                -->
<!--                <div :class="[selected === item.name ? 'chartMenuLinkSelected' : 'chartMenuLink']"-->
<!--                     style="padding-left: 10px">-->
<!--                    {{item.title}}-->
<!--                </div>-->
<!--            </v-row>-->
<!--        </router-link>-->

<!--        <v-divider style="width: 100%; margin: 5px 0"></v-divider>-->
<!--        -->
<!--        <router-link v-for="item in dataLinks" :to="item.link" style="text-decoration: none">-->
<!--            <v-row style="padding: 5px 0" align="center">-->
<!--                <div style="display: flex; width: 30px; justify-content: center">-->
<!--                <i :class="[item.icon, (selected === item.name) ? 'selectedIcon' : 'unselectedIcon']"-->
<!--                   style="font-size: 1.3em"></i>-->
<!--                </div>-->
<!--                <div :class="[selected === item.name ? 'chartMenuLinkSelected' : 'chartMenuLink']"-->
<!--                     style="padding-left: 10px">-->
<!--                    {{item.title}}-->
<!--                </div>-->
<!--            </v-row>-->
<!--        </router-link>-->
        


    </v-container>

</template>

<script>
    import charts from "../charts"
    import ProductMenu from "./product-menu"

    export default {
        props: ['selectedComponent'],
        components: {
            ProductMenu  
        },
        data() {
            return {
                charts,
                weatherLinks: [
                    {
                        name: 'weather',
                        link: '/product/weather',
                        title: 'Weather',
                        icon: 'fa fa-sun'
                    },
                    {
                        name: 'degree-days',
                        link: '/product/degree-days',
                        title: 'Degree Days',
                        icon: 'fa fa-thermometer-half'
                    },
                    
                ],
                dataLinks: [
                    {
                        name: 'import-data',
                        link: '/product/import-data',
                        title: 'Import Data',
                        icon: 'fa fa-cloud-upload-alt'
                    },
                    {
                        name: 'export-data',
                        link: '/product/export-data',
                        title: 'Export Data',
                        icon: 'fa fa-download'
                    },
                ]
            }
        },
        computed: {
            //for some reason using the selectedComponent prop wasn't working so just made this computed property
            selected: function () {
                return this.selectedComponent;
            }
        }
    }

</script>