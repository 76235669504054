<template>
    <v-container style="min-height: 100vh; position: relative; max-width: 1264px;">
        <v-row>
            <v-col cols="4" style="border-right: 1px solid #EEEEEE" v-if="this.$vuetify.breakpoint.mdAndUp">
                <v-container style="position: sticky; top: 64px">
                    <ChartMenuLarge :selectedComponent="selectedComponent"/>
                </v-container>
            </v-col>
            <v-col cols="12" style="border-right: 1px solid #EEEEEE" v-if="this.$vuetify.breakpoint.smAndDown">
                <v-container>
                    <ChartMenuSmall :selectedComponent="selectedComponent"/>
                </v-container>
            </v-col>

            <v-col cols="12" md="8">
                <v-container v-if="selectedComponent === 'overview'">
                    <Overview/>
                </v-container>
                <v-container v-if="selectedComponent === 'load-curve'">
                    <LoadCurve/>
                </v-container>
                <v-container v-if="selectedComponent === 'summary-chart'">
                    <SummaryChart/>
                </v-container>
                <v-container v-if="selectedComponent === 'heat-map'">
                    <HeatMap/>
                </v-container>
                <v-container v-if="selectedComponent === 'time-series'">
                    <TimeSeries/>
                </v-container>
                <v-container v-if="selectedComponent === 'calendar-chart'">
                    <CalendarChart/>
                </v-container>
                <v-container v-if="selectedComponent === 'demand-duration'">
                    <DemandDuration/>
                </v-container>
                <v-container v-if="selectedComponent === 'scatter-plot'">
                    <ScatterPlot/>
                </v-container> 
                <v-container v-if="selectedComponent === 'surface-plot'">
                    <SurfacePlot/>
                </v-container> 
                <v-container v-if="selectedComponent === 'weather'">
                    <WeatherGeneral/>
                </v-container>
                <v-container v-if="selectedComponent === 'degree-days'">
                    <DegreeDays/>
                </v-container>
                <v-container v-if="selectedComponent === 'import-data'">
                    <ImportData/>
                </v-container>
                <v-container v-if="selectedComponent === 'export-data'">
                    <ExportData/>
                </v-container>
            </v-col>
        </v-row>

    </v-container>
</template>

<style>
    .customDescriptionText {
        font-size: 100%;
        line-height: 1.5;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    .listTitle {
        font-weight: 600
    }


</style>

<script>
    import charts from "./charts"
    import Overview from './components/Overview';
    import LoadCurve from './components/LoadCurve';
    import SummaryChart from "./components/SummaryChart";
    import HeatMap from "./components/HeatMap";
    import TimeSeries from "./components/TimeSeries";
    import CalendarChart from "./components/CalendarChart";
    import DemandDuration from "./components/DemandDuration";
    import ScatterPlot from "./components/ScatterPlot";
    import WeatherGeneral from "./components/WeatherGeneral";
    import DegreeDays from "./components/DegreeDays";
    import ImportData from "./components/ImportData";
    import ExportData from "./components/ExportData";
    import ChartMenuLarge from './components/ChartMenuLarge';
    import ChartMenuSmall from './components/ChartMenuSmall'
    import SurfacePlot from "./components/SurfacePlot";

    export default {
        components: {
            SurfacePlot,
            Overview,
            LoadCurve,
            SummaryChart,
            HeatMap,
            TimeSeries,
            CalendarChart,
            DemandDuration,
            ScatterPlot,
            WeatherGeneral,
            DegreeDays,
            ImportData,
            ExportData,
            ChartMenuLarge,
            ChartMenuSmall,
        },
        data() {
            return {
                charts,
            }
        },
        computed: {
            selectedComponent: function () {
                return this.$route.params.section;
            },
        },
        mounted: function () {
            let temp = this.$route.params.section;
        }
    }
</script>