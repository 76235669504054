<template>
    <v-container>
        <v-row >
            <div style="max-width: 800px; font-weight: 600; color: #757575; text-transform: uppercase; padding-bottom: 5px" class="subtitle2">
                {{subtitle}}
            </div>
        </v-row>
        <v-row >
            <div class="customTitle" style="max-width: 800px">
                {{title}}
            </div>
        </v-row>
        <v-row >
            <div class="customSubTitle" style="margin-top: 1.5rem">
                {{description}}
            </div>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        props: ['subtitle', 'title', 'description'],
        
    }
</script>