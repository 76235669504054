<template>
    <div>
        <v-container style="padding: 30px 0;" class="section">
            <v-row
                    style="display: flex; justify-content: center; align-items: center">
                <v-container justify="center">
                    <v-row class="homeSection1Title">
                        
                        <div class="text-center" style="max-width: 800px; margin: auto; padding: 0 10px">
                            Energy data analysis starts with React
                        </div>
                        
                    </v-row>
                    <!--                <v-row>-->
                    <!--                    <div class="orangeBanner"></div>-->
                    <!--                </v-row>-->
                    <v-row style="display: flex; justify-content: center">
                        <div style="color: #424242; padding-top: 1.5rem; padding-left: 10px; padding-right: 10px" class="customSubTitle text-center"
                             id="homeSection1Description">
                            A powerful, cloud-based data analytics platform for energy professionals.
                        </div>
                    </v-row>

                    <v-row justify="center">
                        <div class="homeButtons">

                            <!--                            <v-btn fab-->
                            <!--                                   color="#9e9e9e"-->
                            <!--                                   outlined-->
                            <!--                                   v-scroll-to="{ el: '#homeSection2', duration: 500, easing: 'ease', offset: -60, force: true, cancelable: true, onStart: onStart, onDone: onDone, onCancel: onCancel, x: false, y: true }"-->
                            <!--                            >-->
                            <!--                                <v-icon>fas fa-arrow-down</v-icon>-->
                            <!--                            </v-btn>-->


                            <!--                            <div style="padding: 10px; display: inline-block">-->
                            <!--                                <v-btn large rounded style="color: #9e9e9e " outlined-->
                            <!--                                       v-on:click="toggleExplainerVideoDialog">Watch Video-->
                            <!--                                </v-btn>-->
                            <!--                            </div>-->

                            <div style="padding: 10px; display: inline-block">
                                <router-link to="/free-trial" style="text-decoration: none">
                                    <v-btn large rounded color="secondary" ><div class="customButton">Try for free</div></v-btn>
                                </router-link>
                            </div>

                        </div>
                    </v-row>
                </v-container>


            </v-row>


            <v-row>
                <v-container style="display: flex; justify-items: center">
                    <div style="padding: 0 20px; margin: auto" class="homeSection1Image">
                        <img src="../../../assets/images/CoverPhoto.png"
                             alt="React Energy - Interval Data Analysis"
                             style="width: 100%; max-width: 836px;"

                        />
                    </div>
                </v-container>

            </v-row>

        </v-container>
    </div>
</template>

<style>

</style>

<script>

    import gsap from 'gsap'
    import {CSSRulePlugin} from 'gsap/CSSRulePlugin'


    export default {
        props: ['toggleExplainerVideoDialog'],
        components: {

        },
        mounted: function () {
            // let rule = CSSRulePlugin.getRule(".homeSection1Title:after");
            //
            // // gsap.from('.homeSection1Title', {opacity: 0, duration: 1, y: 50, delay: 1 });
            // // gsap.from('#homeSection1Description', {opacity: 0, duration: 1, y: -50 });
            // // gsap.to('.orangeBanner', {width: '70%', duration: 2});
            //
            // let tl = gsap.timeline({defaults: {duration: 1}});
            //
            // tl.from('.homeSection1Title', {opacity: 0, duration: 1, y: 50, delay: 1})
            //     .from('#homeSection1Description', {opacity: 0, duration: 1, y: 50}, '-=0.5')
            //     .from('.homeButtons', {opacity: 0, duration: 1, y: 50}, '-=0.5')
            //     .from('.homeSection1Image', {opacity: 0, duration: 1, y: 50}, '-=0.5')
            //     .to('.orangeBanner', {width: '70%', duration: 2})

        }
    }
</script>

