<template>
    <v-container>
<!--        <v-row>-->
<!--            <v-select-->
<!--                    :items="totalArray"-->
<!--                    label="Menu"-->
<!--                    outlined-->
<!--                    full-width-->

<!--            >-->
<!--                <template v-slot:label="{item, index}">-->
<!--                    {{item.title}}-->
<!--                    -->
<!--                </template>-->
<!--                <template v-slot:selection="{item, index}">-->

<!--                        <v-list-item>-->
<!--                            <v-list-item-content>-->
<!--                                {{item.title}}-->
<!--                            </v-list-item-content>-->
<!--                        </v-list-item>-->
<!--                </template> -->
<!--                <template v-slot:item="{item, index}">-->
<!--                        <router-link :to="item.link">-->
<!--                            <v-list-item>-->
<!--                                <v-list-item-content>-->
<!--                                    {{item.title}}-->
<!--                                </v-list-item-content>-->
<!--                            </v-list-item>-->
<!--                        </router-link>-->
<!--                        -->
<!--                </template>-->
<!--            </v-select>-->
<!--        </v-row>-->
        <v-row>
            <v-card outlined style="width: 100%">
                <v-container style="margin: 0">
                    <div style="padding: 0 10px">
                        <v-row @click="toggleMenuVisible">
                            <div style="font-weight: bold; flex-grow: 1">
                                Contents Menu
                            </div>
                            <div :v-if="!open" style="display: flex; align-items: center">
                                <i :class="[open ? 'fa fa-chevron-down' : 'fa fa-chevron-right']"  style="font-size: 1em"></i>
            
                            </div>
                        </v-row>
                    </div>
                    
                    <v-row v-if="open">
                        <div style="width: 100%; padding-top: 10px">
                        <v-divider />
                        <v-container>
                            <ProductMenu :selectedComponent="selectedComponent" v-on:toggleMenu="toggleMenuVisible"/>
<!--                            <router-link v-for="chart in charts" :to="chart.link" style="text-decoration: none" @click.native="toggleMenu"> -->
<!--                                <v-row style="padding: 5px 0" align="center">-->
<!--                                    <div style="display: flex; width: 30px; justify-content: center">-->
<!--                                        <i :class="[chart.icon, (selected === chart.name) ? 'selectedIcon' : 'unselectedIcon']"-->
<!--                                           style="font-size: 1.3em"></i>-->
<!--                                    </div>-->
<!--                                    <div :class="[selected === chart.name ? 'chartMenuLinkSelected' : 'chartMenuLink']"-->
<!--                                         style="padding-left: 10px">-->
<!--                                        {{chart.title}}-->
<!--                                    </div>-->
<!--                                </v-row>-->
<!--                            </router-link>-->

<!--                            <v-divider style="width: 100%; margin: 5px 0"></v-divider>-->


<!--                            <router-link v-for="item in weatherLinks" :to="item.link" style="text-decoration: none" @click.native="toggleMenu">-->
<!--                                <v-row style="padding: 5px 0" align="center">-->
<!--                                    <div style="display: flex; width: 30px; justify-content: center">-->
<!--                                        <i :class="[item.icon, (selected === item.name) ? 'selectedIcon' : 'unselectedIcon']"-->
<!--                                           style="font-size: 1.3em"></i>-->
<!--                                    </div>-->

<!--                                    <div :class="[selected === item.name ? 'chartMenuLinkSelected' : 'chartMenuLink']"-->
<!--                                         style="padding-left: 10px">-->
<!--                                        {{item.title}}-->
<!--                                    </div>-->
<!--                                </v-row>-->
<!--                            </router-link>-->

<!--                            <v-divider style="width: 100%; margin: 5px 0"></v-divider>-->

<!--                            <router-link v-for="item in dataLinks" :to="item.link" style="text-decoration: none" @click.native="toggleMenu">-->
<!--                                <v-row style="padding: 5px 0" align="center">-->
<!--                                    <div style="display: flex; width: 30px; justify-content: center">-->
<!--                                        <i :class="[item.icon, (selected === item.name) ? 'selectedIcon' : 'unselectedIcon']"-->
<!--                                           style="font-size: 1.3em"></i>-->
<!--                                    </div>-->
<!--                                    <div :class="[selected === item.name ? 'chartMenuLinkSelected' : 'chartMenuLink']"-->
<!--                                         style="padding-left: 10px">-->
<!--                                        {{item.title}}-->
<!--                                    </div>-->
<!--                                </v-row>-->
<!--                            </router-link>-->
                        </v-container>
                        </div>
                    </v-row>
                </v-container>
                
            </v-card>
        </v-row>
<!--        <v-row class="customTitle" style="font-size: 2em">-->
<!--            Content-->
<!--        </v-row>-->

<!--        <router-link v-for="chart in charts" :to="chart.link" style="text-decoration: none">-->
<!--            <v-row style="padding: 5px 0" align="center">-->
<!--                <div style="display: flex; width: 30px; justify-content: center">-->
<!--                    <i :class="[chart.icon, (selected === chart.name) ? 'selectedIcon' : 'unselectedIcon']"-->
<!--                       style="font-size: 1.3em"></i>-->
<!--                </div>-->
<!--                <div :class="[selected === chart.name ? 'chartMenuLinkSelected' : 'chartMenuLink']"-->
<!--                     style="padding-left: 10px">-->
<!--                    {{chart.title}}-->
<!--                </div>-->
<!--            </v-row>-->
<!--        </router-link>-->

<!--        <v-divider style="width: 100%; margin: 5px 0"></v-divider>-->


<!--        <router-link v-for="item in weatherLinks" :to="item.link" style="text-decoration: none">-->
<!--            <v-row style="padding: 5px 0" align="center">-->
<!--                <div style="display: flex; width: 30px; justify-content: center">-->
<!--                    <i :class="[item.icon, (selected === item.name) ? 'selectedIcon' : 'unselectedIcon']"-->
<!--                       style="font-size: 1.3em"></i>-->
<!--                </div>-->

<!--                <div :class="[selected === item.name ? 'chartMenuLinkSelected' : 'chartMenuLink']"-->
<!--                     style="padding-left: 10px">-->
<!--                    {{item.title}}-->
<!--                </div>-->
<!--            </v-row>-->
<!--        </router-link>-->

<!--        <v-divider style="width: 100%; margin: 5px 0"></v-divider>-->

<!--        <router-link v-for="item in dataLinks" :to="item.link" style="text-decoration: none">-->
<!--            <v-row style="padding: 5px 0" align="center">-->
<!--                <div style="display: flex; width: 30px; justify-content: center">-->
<!--                    <i :class="[item.icon, (selected === item.name) ? 'selectedIcon' : 'unselectedIcon']"-->
<!--                       style="font-size: 1.3em"></i>-->
<!--                </div>-->
<!--                <div :class="[selected === item.name ? 'chartMenuLinkSelected' : 'chartMenuLink']"-->
<!--                     style="padding-left: 10px">-->
<!--                    {{item.title}}-->
<!--                </div>-->
<!--            </v-row>-->
<!--        </router-link>-->


    </v-container>

</template>



<script>
    import charts from "../charts"
    import ProductMenu from './product-menu'

    export default {
        components: {
            ProductMenu  
        },
        props: ['selectedComponent'],
        data() {
            return {
                open: false,
                charts,
                weatherLinks: [
                    {
                        name: 'weather',
                        link: '/product/weather',
                        title: 'Weather',
                        icon: 'fa fa-sun'
                    },
                    {
                        name: 'degree-days',
                        link: '/product/degree-days',
                        title: 'Degree Days',
                        icon: 'fa fa-thermometer-half'
                    },

                ],
                dataLinks: [
                    {
                        name: 'import-data',
                        link: '/product/import-data',
                        title: 'Import Data',
                        icon: 'fa fa-cloud-upload-alt'
                    },
                    {
                        name: 'export-data',
                        link: '/product/export-data',
                        title: 'Export Data',
                        icon: 'fa fa-download'
                    },
                ]
            }
        },
        methods: {
            toggleMenuVisible: function () {
                this.open = !this.open
            }
        },
        computed: {
            //for some reason using the selectedComponent prop wasn't working so just made this computed property
            selected: function () {
                return this.selectedComponent;
            },
            totalArray: function() {
                return charts.concat(this.weatherLinks).concat(this.dataLinks)
            }
        }
    }

</script>