<!--</template>-->
<template >
    <div style="background-color: #f8f8fb; margin-top: 5rem; z-index: 9999; color: #757575">
        <v-container >
            <v-row style="padding: 0 10px">
                <v-col lg="4" cols="12">
                    <v-row class="customTitle light-black" style="font-size: 2em">
                        React Energy
                    </v-row>
                    <div class="footerAddress">
                        <v-row >
                            445 Bowie Blvd
                        </v-row>
                        <v-row >
                            Suite 114 #900
                        </v-row>
                        <v-row >
                            Fort Worth, TX
                        </v-row>
                    </div>
                    <div class="footerContact">
                        <v-row style="display: flex; align-items: center">
                            <i class="fa fa-phone-alt" style="font-size: 1rem; padding-right: 10px; color: #757575"></i>(281) 726-3820
                        </v-row>
                        <v-row style="display: flex; align-items: center">
                            <i class="fa fa-envelope" style="font-size: 1rem; ; padding-right: 10px; color: #757575"></i>contactus@reactenergy.io
                        </v-row>
                    </div>
                    
                </v-col>
                <v-col lg="8" cols="12">
                    <v-row class="customSubTitle" style="font-weight: 400; font-size: 1.25rem; padding-top: 0.5rem">
                        <v-col md="2" cols="12" v-for="link in links" >
                            <router-link :to="link.link" style="text-decoration: none; font-weight: 500" class="light-black">
                                {{link.label}}
                            </router-link>
                            
                        </v-col>

                        
                    </v-row>
                </v-col>
            </v-row>
            <v-divider style="background-color: #bdbdbd"></v-divider>
            <v-row style="padding: 10px;">
                <v-container style="display: flex; justify-content: flex-end">
                    <router-link style="text-decoration: none" to="/privacy">
                    <div>
                        Privacy
                    </div>
                    </router-link>
                    <router-link style="text-decoration: none" to="/terms">
                        <div style="padding-left: 10px">
                            Terms
                        </div>
                    </router-link>
                    
                </v-container>
                
            </v-row>
        </v-container>
    </div>
    
    
</template>

<style>
    .footerAddress {
        font-size: 1.25rem;
        line-height: 1.75rem;
        letter-spacing: -.03062rem;
    }
    .footerContact {
        font-size: 1rem;
        line-height: 1.5rem;
        padding-top: 1rem;
    }
</style>

<script>
    export default {
        data: () => ({
            links: [
                {
                    label: 'Product',
                    link: '/product/overview'
                },
                {
                    label: 'About',
                    link: '/about'
                },
                {
                    label: 'Contact',
                    link: '/contactus'
                },
                {
                    label: 'Pricing',
                    link: '/pricing'
                },
                {
                    label: 'Free Trial',
                    link: '/free-trial'
                },
                {
                    label: 'Login',
                    link: '/login'
                },
            ],
        }),
    }
</script>

<style scoped>
    .light-black {
        color: rgba(0,0,0,0.7)
    }
    
</style>


<!--<script>-->

<!--    import Address from "./components/Address"-->
<!--    import SiteMap from "./components/SiteMap"-->
<!--    import ContactInfo from "./components/ContactInfo"-->
<!--    -->
<!--    export default {-->
<!--        //vuetify: new Vuetify(),-->
<!--        components: {-->
<!--            'custom-address': Address,-->
<!--            'site-map': SiteMap,-->
<!--            'contact-info': ContactInfo-->


<!--        },-->
<!--    }-->
<!--</script>-->