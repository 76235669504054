<template>
    <div style="background-image: linear-gradient(#F9F9F9, #fff); padding: 30px 0">
        <v-container id="homeSection2" style="max-width: 1264px; " >
            <v-row>
                <v-col cols="12" lg="4">
                    <TitleBlock subtitle="overview" title="Speed up energy data analysis"
                                description="The React Energy Portal minimizes the time you spend with the data, so that you can spend more time solving problems. Simply upload your data and immediately take advantage of insights from custom chart queries and weather data."/>
                </v-col>
                <v-col cols="12" lg="8">
                    <OverviewImageSection/>
                </v-col>

            </v-row>
            <OverviewBlocks/>
        </v-container>
    </div>
    
</template>

<script>
    
    import TitleBlock from "../components/TitleBlock";
    import OverviewBlocks from "./components/OverviewBlocks";
    import OverviewImageSection from "./components/OverviewImageSection";

    export default {
        components: {
            TitleBlock,
            OverviewBlocks,
            OverviewImageSection
        },
        methods: {
            
            getImageSrc: function (source) {
                return source
            }
        },
        data() {
            return {
                cardsShown: false,
                items2: [
                    {
                        imageSrc: '../../../assets/images/CustomPNGIcons/MeterWithFile2.png',
                        imgAlt: 'meter-with-data-file',
                        title: 'Upload your energy data',
                        message: 'Upload your energy data'
                    },
                    {
                        imageSrc: '../../../assets/images/CustomPNGIcons/MeterWithFile2.png',
                        imgAlt: 'meter-with-data-file',
                        title: 'Upload your energy data',
                        message: 'Upload your energy data'
                    },
                    {
                        imageSrc: '../../../assets/images/CustomPNGIcons/MeterWithFile2.png',
                        imgAlt: 'meter-with-data-file',
                        title: 'Upload your energy data',
                        message: 'Upload your energy data'
                    },

                ],
                items: [
                    {
                        icon: 'fa fa-cloud-upload-alt fa-stack-1x',
                        iconColor: 'blue darken-2',
                        title: 'Data Storage',
                        message: 'Easily upload and store your data to one centralized location for you and your team.',

                    },
                    {
                        icon: 'fa fa-chart-bar fa-stack-1x',
                        iconColor: 'green darken-2',
                        title: 'Visualize',
                        message: 'Use the various charts to identify trends and outliers in operations of assets behind the meter.',

                    },
                    {
                        icon: 'fa fa-sort-amount-down fa-stack-1x',
                        iconColor: 'red darken-2',
                        title: 'Filter',
                        message: 'Leverage the numerous interval data filters in the system to quickly and easily pinpoint exact energy characteristics you are interested in.',

                    },
                    {
                        icon: 'fa fa-download fa-stack-1x',
                        iconColor: 'orange darken-2',
                        title: 'Export',
                        message: 'Each chart has the option to export the interval data populating it, so that you can easily create more customized charts in your own tools.',

                    },
                    {
                        icon: 'fa fa-user-friends fa-stack-1x',
                        iconColor: 'purple darken-2',
                        title: 'Share',
                        message: 'React is set up for team collaboration. All the members in an organization have access to all of the projects, so there\'s no keeping track of bogged down excel documents.',

                    },
                    {
                        icon: 'fa fa-user-lock fa-stack-1x',
                        iconColor: 'yellow darken-2',
                        title: 'Secure',
                        message: 'Best in class data security to keep your data safe.',

                    },

                ]
            }
        }
    }
</script>
