import Vue from 'vue'
import Router from 'vue-router'
import About from '../components/About.vue'
import Home from '../components/Home/Home'
import ContactUs from "../components/ContactUs";
import Pricing from "../components/Pricing/index";
import Product from "../components/Product/index";
import FreeTrial from "../components/FreeTrial/index";
import SuccessfulAccountActivation from "../components/FreeTrial/SuccessfulAccountActivation";
import Terms from "../components/Legal/Terms"
import Privacy from "../components/Legal/Privacy"


    
Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: __dirname,
    routes: [
        { path: '', component: Home },
        { path: '/', component: Home },
        { path: '/home', component: Home },
        { path: '/about', component: About },
        { path: '/contactus', component: ContactUs },
        { path: '/pricing', component: Pricing },
        { path: '/product/:section?', component: Product },
        { path: '/product', component: Product },
        { path: '/free-trial', component: FreeTrial },
        { path: '/successful-registration', component: SuccessfulAccountActivation },
        { path: '/terms', component: Terms },
        { path: '/privacy', component: Privacy },
    ],
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
});

router.beforeEach((to, from, next) => {
    console.log(to, from, next)
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        // if (!auth.loggedIn()) {
        //     next({
        //         path: '/login',
        //         query: { redirect: from.fullPath }
        //     })
        // } else {
        //     next()
        // }
    } else {
        next() // make sure to always call next()!
    }
})

// function requireAuth (to, from, next) {
//     if (!auth.loggedIn()) {
//         next({
//             path: '/login',
//             query: { redirect: to.fullPath }
//         })
//     } else {
//         next()
//     }
// }

export default router