export default [
    {
        title: 'Load Curve',
        icon: 'fa fa-chart-line',
        coloredIcon: 'fa fa-chart-line customChartLineIcon',
        message: 'Load curves show the energy profile of a meter from midnight to midnight.',
        backgroundImage: 'linear-gradient(to right, #0083B0, #00B4DB)',
        link: '/product/load-curve',
        name: 'load-curve'
    },
    {
        title: 'Summary Chart',
        icon: 'fa fa-chart-bar',
        coloredIcon: 'fa fa-chart-bar customChartBarIcon',
        message: 'Interactive column chart for many different data types.',
        backgroundImage: 'linear-gradient(to right, #4A00E0, #8E2DE2)',
        link: '/product/summary-chart',
        name: 'summary-chart'
    },
    {
        title: 'Heat Map',
        icon: 'fa fa-fire-alt',
        coloredIcon: 'fa fa-fire-alt customFireIcon',
        message: 'Color code demand or consumption in a tabular layout.',
        backgroundImage: 'linear-gradient(#f5af19, #f12711)',
        link: '/product/heat-map',
        name: 'heat-map'
    },
    {
        title: 'Time Series',
        icon: 'fa fa-chart-area',
        coloredIcon: 'fa fa-chart-area customChartAreaIcon',
        message: 'Dynamically move and zoom on an extended load curve of all the data.',
        backgroundImage: 'linear-gradient(to right, #F57F17, #F9A825)',
        link: '/product/time-series',
        name: 'time-series'
    },
    {
        title: 'Calendar Chart',
        icon: 'fa fa-calendar-alt',
        coloredIcon: 'fa fa-calendar-alt customCalendarIcon',
        message: 'The calendar chart displays load curves in a calendar type format.',
        backgroundImage: 'linear-gradient(to right, #F7971E, #FFD200)',
        link: '/product/calendar-chart',
        name: 'calendar-chart'
    },
    {
        title: 'Demand Duration',
        icon: 'fa fa-hourglass-half',
        coloredIcon: 'fa fa-hourglass-half customHourglassIcon', 
        message: 'Demand Duration charts orders the data by magnitude instead of time.',
        backgroundImage: 'linear-gradient(to right, #e53935, #e35d5b)',
        link: '/product/demand-duration',
        name: 'demand-duration'
    },
    
    {
        title: 'Scatter Plot',
        // icon: 'fa fa-chart-bar',
        // coloredIcon: 'fa fa-chart-bar customChartBarIcon',
        message: 'Scatter plots quickly point to outliers in the data.',
        backgroundImage: 'linear-gradient(to top, #000000, #0f9b0f)',
        link: '/product/scatter-plot',
        name: 'scatter-plot'
    },
    {
        title: '3D Surface Plot',
        icon: 'fa fa-cubes',
        coloredIcon: 'fa fa-cubes customSurfacePlotIcon',
        message: 'Surface Plots create a 3D rendering of your energy usage.',
        backgroundImage: 'linear-gradient(to right, #e53935, #e35d5b)',
        link: '/product/surface-plot',
        name: 'surface-plot'
    },

]