<template>
    <div class="aboutMain">
        <v-container >
            <v-row>
                <v-col cols="12" align="center" justify="center">
                    <div >
                        <div >
                            <h1 class="customTitle" id="MissionTitle" style="padding-bottom: 20px">Our Mission</h1>
                        </div>
                        <div class="customSubTitle" id="MissionDescription" style="margin-bottom: 20px">
                            Leverage modern technology and data to drive change towards sustainability.
                        </div>
                        
                        <lottie-player id="MissionLottie"
                                src="https://assets9.lottiefiles.com/packages/lf20_Tzqqc5.json"  background="#fff" speed="1"  style="width: 100%; max-width: 1200px; margin-bottom: 20px"  loop autoplay >
                        </lottie-player>
<!--                        <div >-->
<!--                            <h1 class="customTitle" id="StoryTitle" style="padding-bottom: 20px">Our Story</h1>-->
<!--                        </div>-->
                        <p style="color: #616161; font-size: 1.15em; text-align: center; max-width: 1200px" class="OurStoryDescription">
                            Data plays a key role in our push towards a more sustainable and resiliant grid. However, that data can slow us
                            down if it takes hours to analyze. At React Energy, our primary goal is to minimize the time
                            energy professionals spend with data, giving you more time to solve the problems at hand.

                        </p>
                        <br/>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6" align="center" justify="center">

                        <h1 style="color: #424242; text-align: center; padding-bottom: 10px">Jordan Beacham</h1>
                        <div style="color: #757575; text-align: center; font-size: 1.15em">Email: <a href="mailto:jordan@reactenergy.io" style="color: #1976d2">jordan@reactenergy.io</a></div>
                        <div style="color: #757575; text-align: center; font-size: 1.15em; padding-bottom: 30px">Phone: 817-706-1957 </div>
                        <img src="../assets/images/JordanPicRound2.jpeg" alt="Jordan Beacham - React Energy" width="100%" style="max-width: 400px" />


                </v-col>
                <v-col cols="12" md="6" align="center" justify="center">
                 
                        <h1 style="color: #424242; text-align: center; padding-bottom: 10px">Alex Pettigrew</h1>
                        <div style="color: #757575; text-align: center; font-size: 1.15em">Email: <a href="mailto:alex@reactenergy.io" style="color: #1976d2">alex@reactenergy.io</a></div>
                        <div style="color: #757575; text-align: center; font-size: 1.15em; padding-bottom: 30px">Phone: 281-726-3820 </div>
                        <img src="../assets/images/AlexPicRound3.jpeg" alt="Alex Pettigrew - React Energy" width="100%" style="max-width: 400px"/>
   
                </v-col>
            </v-row>

        </v-container>
    </div>
</template>


<script>
    //import jordanPicAsset from "../assets/images/JordanPic2.png"
    import gsap from 'gsap'
    
    export default {
        name: 'About',
        data(){
            return{
                
            }
        },
        mounted: function() {
            // let tl = gsap.timeline({defaults: {duration: 1}});
            //
            // tl.from('#MissionTitle', {opacity: 0, duration: 1, y: 50, delay: 1})
            //     .from('#MissionDescription', {opacity: 0, duration: 1, y: 50}, '-=0.5')
            //     .from('#MissionLottie', {scale: 0, duration: 1}, '-=0.5')
            //     .from('#OurStoryTitle', {opacity: 0, duration: 1, y: 50}, '-=0.5')
            //     .from('.OurStoryDescription', {opacity: 0, duration: 1, y: 50}, '-=0.5')
            //     .from('.aboutImage', {opacity: 0, duration: 1, y: 50}, '-=0.5')

        }
    }
</script>