<template>
    <div>
        <router-link v-for="item in generalLinks" :to="item.link" style="text-decoration: none"
                     @click.native="onMenuItemClick">
            <v-row style="padding: 5px 0" align="center">
                <div style="display: flex; width: 30px; justify-content: center">
                    <i :class="[item.icon, (selected === item.name) ? 'selectedIcon' : 'unselectedIcon']"
                       style="font-size: 1.3em"></i>
                </div>

                <div :class="[selected === item.name ? 'chartMenuLinkSelected' : 'chartMenuLink']"
                     style="padding-left: 10px">
                    {{item.title}}
                </div>
            </v-row>
        </router-link>
        <v-divider style="width: 100%; margin: 5px 0"></v-divider>
        <router-link v-for="chart in charts" :to="chart.link" style="text-decoration: none" @click.native="onMenuItemClick">
            <v-row style="padding: 5px 0" align="center">
                <div style="display: flex; width: 30px; justify-content: center">
                    <i v-if="chart.icon" :class="[chart.icon, (selected === chart.name) ? 'selectedIcon' : 'unselectedIcon']"
                       style="font-size: 1.3em"></i>
                    <span v-else-if="selected === chart.name">
                        <img src="../../../assets/svg/scatter-chart-svgrepo-com-orange.svg" alt="React Energy - Summary Chart" style="max-height: 20px; max-width: 20px"/>
                    </span>
                    <span v-else>
                     
                        <img src="../../../assets/svg/scatter-chart-svgrepo-com-grey.svg" alt="React Energy - Summary Chart" style="height: 20px; width: 20px"/>
           
                        </span>
                    
                </div>
                <div :class="[selected === chart.name ? 'chartMenuLinkSelected' : 'chartMenuLink']"
                     style="padding-left: 10px">
                    {{chart.title}}
                </div>
            </v-row>
        </router-link>

        <v-divider style="width: 100%; margin: 5px 0"></v-divider>


        <router-link v-for="item in weatherLinks" :to="item.link" style="text-decoration: none"
                     @click.native="onMenuItemClick">
            <v-row style="padding: 5px 0" align="center">
                <div style="display: flex; width: 30px; justify-content: center">
                    <i :class="[item.icon, (selected === item.name) ? 'selectedIcon' : 'unselectedIcon']"
                       style="font-size: 1.3em"></i>
                </div>

                <div :class="[selected === item.name ? 'chartMenuLinkSelected' : 'chartMenuLink']"
                     style="padding-left: 10px">
                    {{item.title}}
                    <v-chip v-if="item.tag" color="rgb(145,25,179,0.2)" label text-color="rgb(145,25,179)" style="height: 100%; padding: 0 6px; margin-left: 5px">{{item.tag}}</v-chip>
                </div>
            </v-row>
        </router-link>

        <v-divider style="width: 100%; margin: 5px 0"></v-divider>

        <router-link v-for="item in dataLinks" :to="item.link" style="text-decoration: none" @click.native="onMenuItemClick">
            <v-row style="padding: 5px 0" align="center">
                <div style="display: flex; width: 30px; justify-content: center">
                    <i :class="[item.icon, (selected === item.name) ? 'selectedIcon' : 'unselectedIcon']"
                       style="font-size: 1.3em"></i>
                </div>
                <div :class="[selected === item.name ? 'chartMenuLinkSelected' : 'chartMenuLink']"
                     style="padding-left: 10px">
                    {{item.title}}
                </div>
            </v-row>
        </router-link>
    </div>
</template>

<style>
    .chartMenuLink {
        display: block;
        font-size: 1.125rem;
        line-height: 1.5rem;
        font-weight: 500;
        position: relative;
        color: #757575;
    }

    .chartMenuLinkSelected {
        display: block;
        font-size: 1.125rem;
        line-height: 1.5rem;
        font-weight: 500;
        position: relative;
        color: #EF6C00;
    }

    .chartMenuLink:hover {
        cursor: pointer;
        color: #000
    }

    .selectedIcon {
        background: -webkit-linear-gradient(#f5af19, #f12711);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .unselectedIcon {
        background: #757575;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

</style>

<script>
    import charts from "../charts";

    export default {
        props: ['selectedComponent', 'toggleMenu'],
        data() {
            return {
                charts,
                generalLinks: [
                    {
                        name: 'overview',
                        link: '/product/overview',
                        title: 'Overview',
                        icon: 'fa fa-laptop'
                    },
                ],
                weatherLinks: [
                    {
                        name: 'weather',
                        link: '/product/weather',
                        title: 'Weather',
                        icon: 'fa fa-sun'
                    },
                    {
                        name: 'degree-days',
                        link: '/product/degree-days',
                        title: 'Degree Days',
                        icon: 'fa fa-thermometer-half',
                        tag: 'Beta'
                    },

                ],
                dataLinks: [
                    {
                        name: 'import-data',
                        link: '/product/import-data',
                        title: 'Import Data',
                        icon: 'fa fa-cloud-upload-alt'
                    },
                    {
                        name: 'export-data',
                        link: '/product/export-data',
                        title: 'Export Data',
                        icon: 'fa fa-download'
                    },
                ]
            }
        },
        computed: {
            //for some reason using the selectedComponent prop wasn't working so just made this computed property
            selected: function () {
                return this.selectedComponent;
            }
        },
        methods: {
            onMenuItemClick: function () {
                this.$emit('toggleMenu')
            }
        }
    }
</script>