<template>
    <div id="homeSection3">
        <v-container>
            <v-row>
                <v-col cols="12" md="3">
                    <v-tabs
                            color="#f57c00"
                            v-model="homeTab"
                            v-show="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
                            show-arrows>
                        <v-tab>Store</v-tab>
                        <v-tab>Organize</v-tab>
                        <v-tab>Visualize</v-tab>
                        <v-tab>Analyze</v-tab>
                        <v-tab>Export</v-tab>
                    </v-tabs>
                    <v-tabs
                            vertical
                            color="#f57c00"
                            v-model="homeTab"
                            align-with-title
                            v-show="$vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl">
                        <v-tab>Store</v-tab>
                        <v-tab>Organize</v-tab>
                        <v-tab>Visualize</v-tab>
                        <v-tab>Analyze</v-tab>
                        <v-tab>Export</v-tab>
                    </v-tabs>


                </v-col>
                <v-col cols="12" md="9" style="color: #616161; font-size: 1.15em">
                    <!--                    @* <div v-show="homeTab === 0"> *@-->
                    <!--                    @*     As Energy Professionals, we are no strangers to collecting data for analysis. Meter data can come from a variety of sources: *@-->
                    <!--                    @*     <div style="padding: 10px 10px 10px 30px"> *@-->
                    <!--                        @*         <ul > *@-->
                    <!--                            @*             <li>Utility</li> *@-->
                    <!--                            @*             <li>Energy Supplier</li> *@-->
                    <!--                            @*             <li>Building Management System</li> *@-->
                    <!--                            @*             <li>Direct from Meter or Submeter</li> *@-->
                    <!--                            @*         </ul> *@-->
                    <!--                        @*     </div> *@-->
                    <!--                    @*     The data can also come in many different styles: *@-->
                    <!--                    @*     <div style="padding: 10px 10px 10px 30px"> *@-->
                    <!--                        @*         <ul > *@-->
                    <!--                            @*             <li>Excel Files</li> *@-->
                    <!--                            @*             <li>CSV</li> *@-->
                    <!--                            @*             <li>XML</li> *@-->
                    <!--                            @*             <li>Green Button</li> *@-->
                    <!--                            @*             <li>And many more...</li> *@-->
                    <!--                            @*         </ul> *@-->
                    <!--                        @*     </div> *@-->
                    <!--                    @* *@-->
                    <!--                    @* </div> *@-->
                    <div v-show="homeTab === 0">
                        <div style="font-size: 1.25em">
                            Store and access your data in one centralized location for you and your team.
                        </div>

                        <br/>
                        In our work as energy professionals, we often found ourselves sharing complex excel files
                        with macros between one another. Every time the excel file would have multiple sheets adding
                        to the confusion,
                        and custom charts we made would get lost in the files. The React Energy Portal solves this
                        problem by giving you and your team
                        one centralized location to host and visualize your interval data.
                        <br/><br/>
                        <div style="color: #757575; font-weight: bold">Easy Upload</div>
                        <br/>
                        We make it easy to upload data by
                        giving you the option to either upload a CSV or XLSX (Microsoft Excel) file directly, or
                        copy and paste your data into a custom table if your data
                        is in a different format.
                    </div>
                    <div v-show="homeTab === 1">
                        <div style="font-size: 1.25em">
                            Organize your data by project and meter for easy navigation.
                        </div>
                        <br/>
                        <div style="color: #757575; font-weight: bold">Projects</div>
                        <br/>
                        It is more common than not to have multiple meters on a single project. Store and manage
                        your meter data by project for easier navigation for yourself and your team.
                        <br/><br/>
                        *Future Release: In the coming months we will be adding the functionality to compare and
                        chart meters in the same project.
                        <br/><br/>
                        <div style="color: #757575; font-weight: bold">Meters</div>
                        <br/>
                        On top of being able to store interval data at the meter level, you also have the ability to
                        keep notes for each meter.
                    </div>
                    <div v-show="homeTab === 2">
                        <div style="font-size: 1.25em">
                            Find what matters in the data.
                        </div>
                        <br/>
                        Bring your interval data to life with dynamic, interactive charts.
                        Our diverse range of chart types give you the ability to quickly spot trends and outliers in
                        the data.

                        <br/><br/>
                        <div style="color: #757575; font-weight: bold">Chart Types</div>
                        <ul style="padding: 10px 10px 10px 30px">
                            <li>Load Curves</li>
                            <li>Summary Charts</li>
                            <li>Calendar Charts</li>
                            <li>Heat Map</li>
                            <li>Time Series</li>
                            <li>Demand Duration</li>
                        </ul>
                        <br/>
                        To lean more about our charting options, visit the
                        <a asp-area="" asp-controller="Home" asp-action="Product"
                           style="text-decoration: none; color: #00b0eb">
                            Product Page
                        </a>
                    </div>
                    <div v-show="homeTab === 3">
                        <div style="font-size: 1.25em">
                            Customize charts through by combining dynamic filters on the data.
                        </div>
                        <br/>
                        Query your data by:
                        <br/><br/>
                        <div style="color: #757575; font-weight: bold">Series Type</div>
                        Choose between kW and kWh, with the added ability to focus on min, max, or average of the
                        values.
                        You can also find the total kWh used or the load factor over a customized period of
                        time.<br/><br/>

                        <div style="color: #757575; font-weight: bold">Series Per</div>
                        Choose to aggregate your data by:<br/>
                        <ul style="padding: 10px 10px 10px 30px">
                            <li>Day</li>
                            <li>Day of the Week</li>
                            <li>Week</li>
                            <li>Month</li>
                            <li>Year</li>
                            <li>Entire Data Set</li>
                        </ul>
                        <br/>
                        <div style="color: #757575; font-weight: bold">Days</div>
                        Focus on weekdays vs. weekends, or any combination of days.<br/><br/>
                        <div style="color: #757575; font-weight: bold">Months</div>
                        Easily compare seasons or see year-to-year changes.<br/><br/>
                        <div style="color: #757575; font-weight: bold">Date Range</div>
                        Narrow down data set by dates. <br/><br/>
                        <div style="color: #757575; font-weight: bold">Time Range</div>
                        Narrow data set by time periods.<br/><br/>

                    </div>
                    <div v-show="homeTab === 4">
                        <div style="font-size: 1.25em">
                            Export raw or filtered interval data.
                        </div>

                        <br/>
                        With every chart that you customize, we give you the ability to easily export that data in
                        case you want to further customize.
                        <br/><br/>


                    </div>


                </v-col>

              
            </v-row>


        </v-container>


    </div>
</template>

<script>
    export default {
        props: ['homeTab']
    }
</script>