<template>
    <v-container>
        <v-row style="display: flex; justify-content: center">
            <v-container style="display: flex; justify-items: center">
                <div style="margin: auto; " class="homeSection1Image" >
                    <img src="../../../../../assets/images/screenshots/DegreeDayScreenshot1.png"
                         alt="React Energy - Interval Data Analysis"
                         class="productImage"
                         style="max-width: 800px; width: 100%; "

                    />
                </div>
            </v-container>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        
    }
</script>