<template>
    <div style="padding: 30px 0">
    <v-container style="max-width: 1264px; margin-top: 2em" v-if="$vuetify.breakpoint.lgAndUp">
        <v-row>
            <v-col cols="12" lg="8">
                <v-row v-observe-visibility="visibilityChanged" >
                    <v-col sm="12" md="6" v-for="chart in charts" class="chartCard">
                        <router-link :to="chart.link" style="text-decoration: none">
                            <ChartCard
                                    :backgroundImage="chart.backgroundImage"
                                    :message="chart.message"
                                    :icon="chart.coloredIcon"
                                    :title="chart.title"
                                    :name="chart.name"
                            />
                        </router-link>
                    </v-col>


                </v-row>
            </v-col>
            <v-col cols="12" lg="4">
                <TitleBlock subtitle="Charts" title="Charts designed for energy analysis" description="Find meaning in the data faster than ever with custom charting options designed to minimize the time you spend analyzing data."/>

            </v-col>
        </v-row>
    </v-container>
        <v-container v-else>
            <v-row >
                <v-col cols="12" >
                    <TitleBlock subtitle="Charts" title="Charts designed for energy analysis" description="Find meaning in the data faster than ever with custom charting options designed to minimize the time you spend analyzing data."/>

                </v-col>
                <v-col cols="12">
                    <v-row v-observe-visibility="visibilityChanged" >
                        <v-col sm="12" md="6" v-for="chart in charts" class="chartCard">
                            <router-link :to="chart.link" style="text-decoration: none">
                                <ChartCard
                                        :backgroundImage="chart.backgroundImage"
                                        :message="chart.message"
                                        :icon="chart.coloredIcon"
                                        :title="chart.title"
                                        :name="chart.name"
                                />
                            </router-link>
                        </v-col>


                    </v-row>
                </v-col>
                
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import charts from '../../../Product/charts'

    import ChartCard from './../ChartCard'
    import {gsap} from 'gsap'
    import TitleBlock from "../components/TitleBlock";
    
    export default {
        components: {
            ChartCard,
            TitleBlock
        },
        mounted: function() {
            // gsap.from('.chartCard', {opacity: 0, duration: 1, stagger: 0.3, y: 50})
        },
        methods: {
          // visibilityChanged: function(isVisible, entry) {
          //     if (isVisible && !this.cardsShown) {
          //         this.cardsShown = true;
          //         gsap.from('.chartCard', {opacity: 0, duration: 1, y: 50, delay: 0, stagger: .3, rotateX: '30deg'});
          //
          //     }
          // }  
        },
        data() {
            return {
                cardsShown: false,
                charts,
                
            }
        }
    }
</script>