<template>
    <V-container>
        <v-row v-observe-visibility="visibilityChanged" style="padding-top: 20px">
            <v-col lg="4" md="6" cols="12" style="padding: 10px 30px; min-height: 100px" class="homeSection4Cards">
                <v-row style="padding: 5px 0">
                    <div>
                        <img src="../../../../../assets/images/CustomPNGIcons/MeterWithFile2.png"
                             alt="React Energy - Summary Chart" style="max-height: 64px; max-width: 64px"/>
                    </div>
                </v-row>
                <v-row style="padding: 5px 0">
                    <div class="headline" style="font-weight: 600">
                        Upload your energy data
                    </div>
                </v-row>
                <v-row style="padding: 5px 0">
                    React makes it easy to upload 15, 30, and 60-minute data quickly so you can move on to what matters.
                </v-row>
            </v-col>
            <v-col lg="4" md="6" cols="12" style="padding: 10px 30px; min-height: 100px" class="homeSection4Cards">
                <v-row style="padding: 5px 0">
                    <div>
                        <img src="../../../../../assets/images/CustomPNGIcons/analyzeChartSmall2.png"
                             alt="React Energy - Summary Chart" style="max-height: 64px; max-width: 64px"/>
                    </div>
                </v-row>
                <v-row style="padding: 5px 0">
                    <div class="headline" style="font-weight: 600">
                        Bring your data to life
                    </div>
                </v-row>
                <v-row style="padding: 5px 0">
                    Move through your data at the speed of thought. With eight chart types and many custom queries you
                    can minimize the time you spend analyzing data.
                </v-row>
            </v-col>
            <v-col lg="4" md="6" cols="12" style="padding: 10px 30px; min-height: 100px" class="homeSection4Cards">
                <v-row style="padding: 5px 0">
                    <div>
                        <img src="../../../../../assets/images/CustomPNGIcons/OrangeThermometerBig.png"
                             alt="React Energy - Summary Chart" style="max-height: 64px; max-width: 64px"/>
                    </div>
                </v-row>
                <v-row style="padding: 5px 0">
                    <div class="headline" style="font-weight: 600">
                        Weather Data API
                    </div>
                </v-row>
                <v-row style="padding: 5px 0">
                    Immediately see the effects of weather on energy consumption with raw weather data and degree-days
                    calculations.
                </v-row>
            </v-col>

        </v-row>
    </V-container>
    
</template>

<script>

    import gsap from 'gsap'
    export default {
        methods: {
            visibilityChanged: function (isVisible, entry) {
                // if (isVisible && !this.cardsShown) {
                //     this.cardsShown = true;
                //     gsap.from('.homeSection4Cards', {opacity: 0, duration: 1, y: 50, stagger: .3});
                //
                // }

            },
        }
    }
</script>