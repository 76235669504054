import Vue from 'vue'
import App from './App.vue'
//import VueRouter from 'vue-router'
import router from './router/index'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import VueScrollTo from 'vue-scrollto'
import '@fortawesome/fontawesome-free/css/all.css'
import VueParticles from 'vue-particles'
import HighchartsVue from 'highcharts-vue'
import VueObserveVisibility from 'vue-observe-visibility'
import '@mdi/font/css/materialdesignicons.css'
import VueAnalytics from 'vue-analytics'


Vue.use(HighchartsVue);

Vue.use(VueParticles);
Vue.use(VueObserveVisibility);
Vue.use(Vuetify);

Vue.use(VueScrollTo);

//Vue.use(VueRouter);

Vue.use(VueMoment, {
  moment,
});
Vue.use(VueAnalytics, {
  id: 'UA-127153474-3', 
  router
})

Vue.filter('utcAsLocal', function (value) {
  return moment.utc(value).local();
});

Vue.config.productionTip = false;

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#BDBDBD',
        secondary: '#f57c00',
        accent: '#8c9eff',
        error: '#b71c1c',
      },
    },
  },
})

new Vue({
  render: h => h(App),
  router: router,
  vuetify,
  icons: {
    iconfont: 'fa',
    
  },
}).$mount('#app');
