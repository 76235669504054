<template>
    <v-container>
        <v-row>
            <v-col cols="4">
                <v-card
                        class="mx-auto"
                        max-width="400"
                >
                    <lottie-player
                            src="https://assets8.lottiefiles.com/packages/lf20_UExBCF.json"  background="#3490dc" speed="1"  style="width: 100%; height: 400px;"  loop autoplay >
                    </lottie-player>

                    <v-card-subtitle class="pb-0">Number 10</v-card-subtitle>

                    <v-card-text class="text--primary">
                        <div>Whitehaven Beach</div>

                        <div>Whitsunday Island, Whitsunday Islands</div>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn
                                color="orange"
                                text
                        >
                            Share
                        </v-btn>

                        <v-btn
                                color="orange"
                                text
                        >
                            Explore
                        </v-btn>
                    </v-card-actions>
                </v-card>

            </v-col>
            <v-col cols="4">
                <v-card
                        class="mx-auto"
                        max-width="400"
                >
                    <lottie-player
                            src="https://assets8.lottiefiles.com/packages/lf20_2RqhWi.json"  background="#fff" speed="0.5"  style="width: 100%; height: 400px;"  loop autoplay >
                    </lottie-player>

                    <v-card-subtitle class="pb-0">Number 10</v-card-subtitle>

                    <v-card-text class="text--primary">
                        <div>Whitehaven Beach</div>

                        <div>Whitsunday Island, Whitsunday Islands</div>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn
                                color="orange"
                                text
                        >
                            Share
                        </v-btn>

                        <v-btn
                                color="orange"
                                text
                        >
                            Explore
                        </v-btn>
                    </v-card-actions>
                </v-card>

            </v-col>
            <v-col cols="4">
                <v-card
                        class="mx-auto"
                        max-width="400"
                >
                    <lottie-player
                            src="https://assets9.lottiefiles.com/packages/lf20_qMPb1w.json"  background="#38c172" speed="1"  style="width: 100%; height: 400px;"  loop autoplay >
                    </lottie-player>

                    <v-card-subtitle class="pb-0">Number 10</v-card-subtitle>

                    <v-card-text class="text--primary">
                        <div>Whitehaven Beach</div>

                        <div>Whitsunday Island, Whitsunday Islands</div>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn
                                color="orange"
                                text
                        >
                            Share
                        </v-btn>

                        <v-btn
                                color="orange"
                                text
                        >
                                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                    <path d="../../../assets/svg/scatter-chart-svgrepo-com.svg"/>
                                </svg>

                        </v-btn>
                    </v-card-actions>
                </v-card>

            </v-col>

        </v-row>
    </v-container>
</template>

<script>
    import ScatterSVG from '../../../assets/svg/scatter-chart-svgrepo-com.svg'
    
    export default {
        components: {
            ScatterSVG
        }
    }
</script>