<template>
    <v-container>
        <v-row v-observe-visibility="visibilityChanged" style="padding-top: 20px">
            <v-col lg="4" md="6" cols="12" style="padding: 10px 30px; min-height: 100px" class="homeSection4Cards">
                <!--                   v-show="cardsShown"-->

                <v-row style="padding: 5px 0">
                    <div>
                        <img src="../../../../../assets/images/CustomPNGIcons/WeatherLocationSVG.svg"
                             alt="React Energy - Summary Chart" style="max-height: 64px; max-width: 64px"/>
                    </div>
                </v-row>
                <v-row style="padding: 5px 0">
                    <div class="headline" style="font-weight: 600">
                        Nearby weather stations
                    </div>
                </v-row>
                <v-row style="padding: 5px 0">
                    React pulls data from nearby weather stations based on postal code.
                </v-row>
            </v-col>
            <v-col lg="4" md="6" cols="12" style="padding: 10px 30px; min-height: 100px" class="homeSection4Cards"
            >
                <v-row style="padding: 5px 0">
                    <div>
                        <img src="../../../../../assets/images/CustomPNGIcons/OrangeThermometerBig.png"
                             alt="React Energy - Summary Chart" style="max-height: 64px; max-width: 64px"/>
                    </div>
                </v-row>
                <v-row style="padding: 5px 0">
                    <div class="headline" style="font-weight: 600">
                        Multiple data points
                    </div>
                </v-row>
                <v-row style="padding: 5px 0">
                    React pulls in more than just raw temperature data. Acces data points such as humidity, dew point,
                    precipitation, and more.
                </v-row>
            </v-col>
            <v-col lg="4" md="6" cols="12" style="padding: 10px 30px; min-height: 100px" class="homeSection4Cards"
            >
                <v-row style="padding: 5px 0">
                    <div>
                        <img src="../../../../../assets/images/CustomPNGIcons/DegreeDays.svg"
                             alt="React Energy - Summary Chart" style="max-height: 64px; max-width: 64px"/>
                    </div>
                </v-row>
                <v-row style="padding: 5px 0">
                    <div class="headline" style="font-weight: 600">
                        Degree days
                    </div>
                </v-row>
                <v-row style="padding: 5px 0">
                    Calculate degree days with custom cooling degree day and heating degree day base values.
                </v-row>
            </v-col>

        </v-row>
    </v-container>
   
</template>

<script>
    import gsap from 'gsap'
    export default {
        methods: {
            visibilityChanged: function (isVisible, entry) {
                // if (isVisible && !this.cardsShown) {
                //     this.cardsShown = true;
                //     gsap.from('.homeSection4Cards', {opacity: 0, duration: 1, y: 50, stagger: .3});
                //
                // }

            },
        },
    }
</script>