<template>
    <div style="background-image: linear-gradient(#F9F9F9, #fff); padding: 30px 0">
        <v-container id="homeSection2" style="max-width: 1264px; ">

            <v-row>
                <v-col cols="12" lg="4">
                    <TitleBlock
                            subtitle="Weather"
                            title="Leverage weather station data"
                            description="Comparing energy consumption with weather data provides valuable insights into the operations of facilities. React searches nearby weather stations to provide you with high quality weather data."
                    />

                </v-col>
                <v-col cols="12" lg="8">
                    <WeatherImageSection/>
                </v-col>


            </v-row>
            <WeatherBlocks/>


        </v-container>
    </div>
</template>

<script>

    import TitleBlock from "../components/TitleBlock";
    import WeatherImageSection from "./components/WeatherImageSection";
    import WeatherBlocks from "./components/WeatherBlocks";

    export default {
        components: {
            TitleBlock,
            WeatherImageSection,
            WeatherBlocks
        },

        data() {
            return {
                cardsShown: false,
                items: [
                    {
                        icon: 'fa fa-cloud-upload-alt fa-stack-1x',
                        iconColor: 'blue darken-2',
                        title: 'Data Storage',
                        message: 'Easily upload and store your data to one centralized location for you and your team.',

                    },
                    {
                        icon: 'fa fa-chart-bar fa-stack-1x',
                        iconColor: 'green darken-2',
                        title: 'Visualize',
                        message: 'Use the various charts to identify trends and outliers in operations of assets behind the meter.',

                    },
                    {
                        icon: 'fa fa-sort-amount-down fa-stack-1x',
                        iconColor: 'red darken-2',
                        title: 'Filter',
                        message: 'Leverage the numerous interval data filters in the system to quickly and easily pinpoint exact energy characteristics you are interested in.',

                    },
                    {
                        icon: 'fa fa-download fa-stack-1x',
                        iconColor: 'orange darken-2',
                        title: 'Export',
                        message: 'Each chart has the option to export the interval data populating it, so that you can easily create more customized charts in your own tools.',

                    },
                    {
                        icon: 'fa fa-user-friends fa-stack-1x',
                        iconColor: 'purple darken-2',
                        title: 'Share',
                        message: 'React is set up for team collaboration. All the members in an organization have access to all of the projects, so there\'s no keeping track of bogged down excel documents.',

                    },
                    {
                        icon: 'fa fa-user-lock fa-stack-1x',
                        iconColor: 'yellow darken-2',
                        title: 'Secure',
                        message: 'Best in class data security to keep your data safe.',

                    },

                ]
            }
        }
    }
</script>
