<template>
    <v-container>
        <v-row class="customTitle" style="padding-bottom: 30px">
            Demand Duration
        </v-row>
        <v-row>
            <v-container style="display: flex; justify-items: center">
                <div style="margin: auto" class="homeSection1Image">
                    <img src="../../../assets/images/screenshots/DemandDurationScreenshot.png"
                         alt="React Energy - Interval Data Analysis"
                         style="width: 100%"
                         class="productImage"
                    />
                </div>
            </v-container>
        </v-row>
        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">
            General
        </v-row>
        <v-row class="customDescriptionText">
            The demand duration tool is one of the less common, but very insightful chart types when it comes to energy
            data. It sorts the data from maximum, on the left, the minimum, on the right. The percentage displayed on
            the x-axis shows you the percent of time a meter's demand is above a certain threshold. 

        </v-row>

        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">
            Data Types
        </v-row>
        <v-row class="customDescriptionText">
            <ul>
                <li>Kilowatts (kW)</li>
                <li>Kilowatt-hours (kWh)</li>
            </ul>

        </v-row>
    </v-container>
</template>


<script>
    import SummaryLevels from './FilterOptions/SummaryLevels'
    import SeriesType from './FilterOptions/SeriesType'
    import Days from './FilterOptions/Days'
    import Months from './FilterOptions/Months'

    export default {
        components: {
            SummaryLevels,
            SeriesType,
            Days,
            Months
        }
    }
</script>