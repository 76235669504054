<template>
    <v-card
            class="mx-auto custom-card"
            outlined
    >
        <v-list-item three-line>
            <v-list-item-avatar
                    tile
                    size="80"

            >

                <div style="border-radius: 5px">
                    <div style="padding: 5px">
                        <div>
                            <span >
<!--                                <i :class="icon" ></i>-->
<!--                                <img src="../../../assets/images/CustomPNGIcons/ChartIcons/LoadCurveIconColor.png" alt="React Energy - Summary Chart" style="max-height: 48px; max-width: 48px"/>-->
                                <ChartIconFunction :chartType="name"/>
                            </span>
<!--                            <span v-else>-->
<!--                                 <img src="../../../assets/svg/scatter-chart-svgrepo-com.svg" alt="React Energy - Summary Chart" style="max-height: 48px; max-width: 48px"/>-->
<!--                            </span>-->
                           
                        </div>
                        <!--                        <v-icon color="white" style="font-size: 3em">{{icon}}</v-icon>-->
                    </div>

                </div>


            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title class="headline mb-1">{{title}}</v-list-item-title>
                <v-list-item-subtitle >{{message}}</v-list-item-subtitle>

            </v-list-item-content>


        </v-list-item>

<!--        <v-card-actions>-->
<!--            <span class="subtitle-1" style="padding: 5px">Learn More</span>-->

<!--        </v-card-actions>-->
    </v-card>
</template>

<script>
    import ChartIconFunction from "./IconFunction/ChartIconFunction";
    
    export default {
        props: ['backgroundImage', 'message', 'icon', 'title', 'name'],
        components: {
            ChartIconFunction
        }
        // data() {
        //     return {
        //         backgroundImage: backgroundImage
        //     }
        // }
    }
</script>



<style>
    .custom-card {
        
        animation: moveDown;
        animation-duration: .5s;
        animation-fill-mode: forwards;
    }

    .custom-card:hover {
        cursor: pointer;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        animation: moveUp;
        animation-duration: .5s;
        animation-fill-mode: forwards;
        

    }
    
    @keyframes moveUp {
        to {
            transform: translateY(-5px);
        }
    }
    
    @keyframes moveDown {
        to {
            transform: translateY(5px);
        }
    }

    

    


</style>