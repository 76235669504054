<template>

    <v-row style="display: flex; justify-content: center; min-height: calc(100vh - 64px); padding-top: 3em; padding-right: 5px; padding-left: 3px"
          >
        <v-col cols="12" lg="7" class="hidden-md-and-down">
            <!--            <v-container>-->
            <!--                <div style="padding: 0 20px">-->
            <!--                    <img src="../../assets/images/summaryChartMac.png" alt="React Energy - Summary Chart" style="max-width:100%;-->
            <!--max-height:100%;"/>-->
            <!--                </div>-->
            <!--                -->
            <!--            </v-container>-->
            <v-row id="lottieImage1">
                <lottie-player
                        src="https://assets9.lottiefiles.com/packages/lf20_X1uPhX.json" background="#fff" speed="1"
                        style="width: 100%; height: 100%;" loop autoplay>
                </lottie-player>
            </v-row>
            
        </v-col>
        <v-col xs="12" lg="5">
            <v-container>
                <v-row style="display: flex; justify-content: center; padding: 5px 0" class="gsapForm">
                    <div style='font-family: Google Sans,Roboto,Arial,sans-serif;
                 font-size: 1.75rem;
                 font-weight: 400;'>
                        14-Day Free Trial
                    </div>
                </v-row>
                <v-row style="display: flex; justify-content: center" class="gsapForm">
                    <div style="max-width: 400px; text-align: center">

                        <span style="font-weight: 600">No credit card required.</span> Enter a valid email address so we
                        can verify your account by email. Cancel anytime.
                    </div>

                </v-row>
                <v-row style="display: flex; justify-content: center">


                    <v-form v-model="valid" style="width: 100%; max-width: 500px" ref="form">
                        <v-container>
                            <v-row style="display: flex; justify-content: center" v-for="field in fields"
                                   class="gsapForm">
                                <div style="min-width: 80%">
                                    <v-text-field
                                            outlined
                                            v-model='field.value'
                                            v-bind:rules="field.rules"
                                            v-bind:label="field.label"
                                            v-bind:required="field.required"
                                    ></v-text-field>
                                </div>

                            </v-row>
                            <v-row style="display: flex; justify-content: center" class="gsapForm">
                                <div style="min-width: 80%">
                                    <v-select
                                            v-model="reference"
                                            :items="select"
                                            outlined
                                            label="How did you hear about us?"
                                    >

                                    </v-select>
                                </div>
                            </v-row>
                            <v-row style="display: flex; justify-content: center">
                                <div style="min-width: 80%">
                                    <v-text-field
                                            label="Please describe how you heard about us"
                                            v-show="reference === 'Other'"
                                            outlined

                                    >

                                    </v-text-field>
                                </div>
                            </v-row>
                            <v-row style="display: flex; justify-content: center" class="gsapForm">
                                <div style="min-width: 80%; display: flex; justify-content: center">
                                    <div @click="toggleAcceptTermsAndPrivacy" style="display: inline-block">
                                        <i :class="acceptTermsAndPrivacy.value ? 'fa fa-check-square customCheckbox' : 'far fa-square  customCheckbox'" style="color: #f57c00; font-size: 1.5em"></i>
                                    </div>
                                    <div style="display: inline-block; padding-left: 5px">
                                        I agree to the 

                                                <a
                                                    target="_blank"
                                                    href="https://reactenergy.io/terms"
                                                    @click.stop
                                                    v-on="on"
                                                    class="textLink"
                                                    style="color: #42A5F5"
                                            >
                                                Terms
                                            </a> and
                                                <a
                                                        target="_blank"
                                                        href="https://reactenergy.io/privacy"
                                                        @click.stop
                                                        v-on="on"
                                                        class="textLink"
                                                        style="color: #42A5F5"
                                                >
                                                    Privacy Policy
                                                </a> 

                                    </div>
                                </div>
                            </v-row>
                            <v-row style="display: flex; justify-content: center; padding: 20px 0" class="gsapForm">

                                <v-btn color="secondary" large rounded @click="submitFreeTrial" v-if="submitting"
                                       style="min-width: 80%">
                                    <PulseLoader color="#fff"/>
                                </v-btn>
                                <v-btn color="secondary" large rounded @click="submitFreeTrial" v-if="!submitting"
                                       style="min-width: 80%"><div class="customButton">Submit Registration</div>
                                </v-btn>

                            </v-row>
                        </v-container>
                    </v-form>


                </v-row>

            </v-container>
        </v-col>

        <v-snackbar
                v-model="snackbar.open"
                :color="snackbar.color"
                top
        >
            {{ snackbar.message }}
        </v-snackbar>
    </v-row>
    
</template>

<style>
    .customCheckbox:hover {
        cursor: pointer
    }
</style>

<script>
    import Axios from "axios";
    import gsap from 'gsap';
    import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

    export default {
        components: {
            PulseLoader
        },
        mounted: function () {
            let tl = gsap.timeline({defaults: {duration: 1}});

            tl.from('.gsapForm', {opacity: 0, duration: 1, y: 50, delay: 1, stagger: 0.2})

        },
        data: () => ({
            submitting: false,
            snackbar: {
                message: null,
                open: false,
                color: '#4caf50'
            },
            valid: false,
            defaultStripePlanId: null,
            select: ['Google', 'Bing', 'Facebook', 'LinkedIn', 'Word of mouth', 'Other'],
            reference: null,
            fields: [
                {
                    label: 'First Name',
                    name: 'firstName',
                    value: '',
                    required: true,
                    rules: [
                        v => !!v || 'Name is required',
                        v => v.length <= 15 || 'Name must be less than 10 characters',
                    ],
                },
                {
                    label: 'Last Name',
                    name: 'lastName',
                    value: '',
                    required: true,
                    rules: [
                        v => !!v || 'Name is required',
                        v => v.length <= 15 || 'Name must be less than 10 characters',
                    ],
                },
                {
                    label: 'Email',
                    name: 'email',
                    value: '',
                    required: true,
                    rules: [
                        v => !!v || 'E-mail is required',
                        v => /.+@.+/.test(v) || 'E-mail must be valid',
                    ],
                },
                {
                    label: 'Company Name',
                    name: 'companyName',
                    value: '',
                    required: true,
                    rules: [
                        v => !!v || 'Company Name is required',

                    ],
                },
                

            ],
            acceptTermsAndPrivacy: {
                label: ``,
                name: 'acceptTermsAndPrivacy',
                value: false,
                required: true
            },
            // firstname: '',
            // lastname: '',
            // nameRules: [
            //     v => !!v || 'Name is required',
            //     v => v.length <= 10 || 'Name must be less than 10 characters',
            // ],
            // email: '',
            // emailRules: [
            //     v => !!v || 'E-mail is required',
            //     v => /.+@.+/.test(v) || 'E-mail must be valid',
            // ],
        }),
        created: function () {
            this.getActivePlans();
        },
        methods: {
            toggleAcceptTermsAndPrivacy: function() {

              this.acceptTermsAndPrivacy.value = !this.acceptTermsAndPrivacy.value
            },
            testSubmit: function () {
                let tl = gsap.timeline({defaults: {duration: 1}});

                tl.to('.gsapForm', {opacity: 0, duration: .5, x: 300, delay: 0, stagger: 0.05})
                    .to('#lottieImage1', {opacity: 0, duration: .5, x: -300, delay: 0}, "-=0.5");
                
                this.$router.push({path: '/successful-registration'});
                
            },
            submitFreeTrial: function () {
                this.$refs.form.validate();
                if (this.valid && this.acceptTermsAndPrivacy.value) {
                    this.submitting = true;

                    let object = {
                        "User": {
                            "Email": this.fields[2].value,
                            "FirstName": this.fields[0].value,
                            "LastName": this.fields[1].value,
                            "PhoneNumber": "",
                        },
                        "Organization": {
                            "Name": this.fields[3].value,
                            "Address1": "",
                            "City": "",
                            "State": "",
                            "PostalCode": "",
                            "Phone": ""
                        },
                        StripePaymentMethodId: "",
                        StripePlanId: this.defaultStripePlanId

                    }

                    //submit contactus form using axios
                    Axios.post('/api/account/Signup', object).then(response => {
                        if (response.data.isSuccess) {
                            
                            let tl = gsap.timeline({defaults: {duration: 1}});
                            tl.to('.gsapForm', {opacity: 0, duration: .5, x: 300, delay: 0, stagger: 0.05})
                                .to('#lottieImage1', {opacity: 0, duration: .5, x: -300, delay: 0}, "-=0.5");
                            this.$router.push({path: '/successful-registration'});
                            
                        } else {
                            this.snackbar = {
                                message: response.data.errors[0].message,
                                open: true,
                                color: '#f44336'
                            }
                        }
                        this.submitting = false;
                    });
                    
                } else if (!this.acceptTermsAndPrivacy.value) {
                    this.snackbar = {
                        message: 'You must agree to the terms and privacy policy.',
                        open: true,
                        color: '#f44336'
                    }
                } else {
                    this.snackbar = {
                        message: 'Please supply your name, email, and company name before submitting',
                        open: true,
                        color: '#f44336'
                    }
                }

            },
            getActivePlans: function () {
                Axios.post('/api/account/GetActiveProductPlans', {}).then(response => {
                    let temp = response.data.products[0].plans.filter(plan => plan.interval === 'month');
                    this.defaultStripePlanId = temp[0].stripePlanId;

                });
            },
        }
    }
</script>