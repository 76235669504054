<template>

    <div >

        <div>
            

<!--            <particles-example style="color: #000000;-->
<!--                position: absolute;-->
<!--                top: 0;-->
<!--                left: 0;-->
<!--                z-index: -100; height: 100vh; width: 100vw"/>-->
            
            <HomeSection1 :toggleExplainerVideoDialog="toggleExplainerVideoDialog"/>
        </div>

<!--        <v-container>-->
<!--            <v-divider></v-divider>-->
<!--        </v-container>-->
<!--        <HomeSection5 />-->

<!--        <v-container>-->
<!--            <v-divider></v-divider>-->
<!--        </v-container>-->

        <HomeSectionGeneral />
        <v-container style="padding-bottom: 0; margin-bottom: 0">
            <v-divider ></v-divider>
        </v-container>
        <HomeSectionIntervalDataCharts />
        

<!--        <v-container>-->
<!--            <v-divider></v-divider>-->
<!--        </v-container>-->

        <HomeSectionDegreeDays />
        
<!--     <GetStartedBanner v-if="$vuetify.breakpoint.mdAndUp"/>-->
        <v-container>
            <GetStartedBanner />
        </v-container>
     
               
        
<!--        <HomeSection2 />-->
        
    </div>
</template>



<script>

    import Dialog from "../Dialog";
    import Particles from "../../components/components/Particles/Particles";
    import HomeSection1 from "./components/HomeSection1";
    import HomeSection3 from './components/HomeSection3';
    import HomeSectionGeneral from './components/OverviewSection/HomeSectionGeneral';
    import HomeSection5 from './components/HomeSection5';
    import HomeSectionDegreeDays from './components/WeatherSection/HomeSectionDegreeDays';
    import HomeSectionIntervalDataCharts from './components/ChartsSection/HomeSectionIntervalDataCharts';
    import LottieTest from "./components/LottieTest";
    import GetStartedBanner from "./components/GetStartedBanner";

    export default {
        components: {
            'test': Dialog,
            'particles-example': Particles,
            HomeSection1,
            HomeSection3,
            HomeSectionGeneral,
            HomeSection5,
            HomeSectionDegreeDays,
            HomeSectionIntervalDataCharts,
            LottieTest,
            GetStartedBanner
        },
        name: 'Home',
        data() {
            return {
                homeTab: 0,
                explainerVideoDialog: false,
                dialog: true
            }
        },
        watch: {
            explainerVideoDialog(val) {
                if (!val) {
                    $('.explainerVideo').each(function () {
                        this.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*')
                    });
                }
            }
        },
        methods: {
            toggleMenu: function () {
                this.menuOpen = !this.menuOpen
            },
            test: function () {
                console.log('hello')
            },
            toggleExplainerVideoDialog: function () {
                this.explainerVideoDialog = !this.explainerVideoDialog;

            },
            smoothScroll: function (target) {
                alert("hello");
                var scrollContainer = target;
                do { //find scroll container
                    scrollContainer = scrollContainer.parentNode;
                    if (!scrollContainer) return;
                    scrollContainer.scrollTop += 1;
                } while (scrollContainer.scrollTop == 0);

                var targetY = 0;
                do { //find the top of target relatively to the container
                    if (target == scrollContainer) break;
                    targetY += target.offsetTop;
                } while (target == target.offsetParent);

                scroll = function (c, a, b, i) {
                    i++;
                    if (i > 30) return;
                    c.scrollTop = a + (b - a) / 30 * i;
                    setTimeout(function () {
                        scroll(c, a, b, i);
                    }, 20);
                }
                // start scrolling
                scroll(scrollContainer, scrollContainer.scrollTop, targetY, 0);
            }
        }
    }
</script>