import { render, staticRenderFns } from "./ChartMenuSmall.vue?vue&type=template&id=ccd4e1bc&"
import script from "./ChartMenuSmall.vue?vue&type=script&lang=js&"
export * from "./ChartMenuSmall.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports