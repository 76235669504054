<template>
    <div>
        
    
    <v-app v-if="$vuetify.breakpoint.xsOnly">
        
        

        <Slideout :touch="false" side="right" :toggleSelectors="['.toggle-button', '.another-toggle']" @on-open="logger">
            <nav id="menu">
                <small-menu :toggleSlideout="toggleSlideout"></small-menu>
            </nav>
            
            <main  id="panel" >
                <top-navbar-small :toggleSlideout="toggleSlideout"/>
                <transition name="router-anim">
                    <router-view></router-view>
                </transition>
                <website-footer-large></website-footer-large>
            </main>

            
        </Slideout>

    </v-app>


    <v-app v-else>
        <top-navbar-large/>

            <main style="margin-top: 64px">
                <transition name="router-anim">
                    <router-view></router-view>
                </transition>
            </main>

        

        <website-footer-large></website-footer-large>

    </v-app>
    </div>
</template>

<style>
    @import './assets/css/custom.css';
    @import '~animate.css';

    .router-anim-enter-active {
        animation: coming 1s;
        animation-delay: 0s;
        /*opacity: 0;*/
    }

    .router-anim-leave-active {
        animation: going 1s;
    }
    
    

    @keyframes going {
        from {
            opacity: 1
        }
        to {
            opacity: 0
        }
    }

    @keyframes coming {
        from {
            opacity: 0
        }
        to {
            opacity: 1
        }
    }

</style>

<style>
    body {
        width: 100%;
        height: 100%;
        margin: 0;
    }
    
    .slideout-shadow {
        
    }

    .slideout-menu {
        position: fixed;
        top: 0;
        bottom: 0;
        width: 256px;
        height: 100vh;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        z-index: 0;
        display: none;
        /*background-image: linear-gradient(to left, #fff, #757575);*/
        background-image: url("./assets/svg/CustomSVGs/OrangeBackground.svg");
        background-position: -900px 0;
        /*color: white;*/
    }

    .slideout-menu-left {
        left: 0;
    }

    .slideout-menu-right {
        right: 0;
    }

    .slideout-panel {
        /*background-color: #4b5;*/
        /*color: white;*/
        position: relative;
        z-index: 1;
        will-change: transform;
        background-color: white;
        
    }

    .slideout-open,
    .slideout-open body,
    .slideout-open .slideout-panel {
        overflow: hidden;
        box-shadow: 0 0 30px
        
    }

    .slideout-open .slideout-menu {
        display: block;
    }
    
    .toggle-button {
        color: blue
    }

</style>


<script>

    import Axios from 'axios';
    import $ from 'jQuery';
    import Dialog from "./components/Dialog";
    import TopNavbarLarge from "./components/components/Navbars/TopNavbarLarge"
    import TopNavbarSmall from "./components/components/Navbars/TopNavbarSmall"
    import FooterLarge from "./components/components/Footer/Footer-Large"
    import Slideout from "vue-slideout";
    import SmallMenu from './components/components/Navbars/SmallMenu'


    //const url = window.location.href;
    //const breakdown = url.split('/');
    //const page = breakdown[breakdown.length - 1];

    export default {
        //vuetify: new Vuetify(),
        components: {
            'test': Dialog,
            'top-navbar-large': TopNavbarLarge,
            'top-navbar-small': TopNavbarSmall,
            'website-footer-large': FooterLarge,
            Slideout,
            'small-menu': SmallMenu
        },
        data() {
            return {


                currentPage: true,
                contactUs: {
                    name: null,
                    email: null,
                    phone: null,
                    message: null
                },
                contactUsSnackbar: {
                    open: false,
                    message: null,
                    color: null,
                },
                product: "Product",
                menuOpen: false,
                length: 3,
                onboarding: 0,
                loadCurve: null,
                visibleProducts: {
                    dataFilter: false,
                    loadCurve: false,
                    heatMap: false,
                    summaryChart: false,
                    timeSeries: false,
                    demandDuration: false,
                    calendarChart: false
                },
                message: 'Hello Vue!',
                items: [
                    {title: 'Load Curves'},
                    {title: 'Summary Charts'},
                    {title: 'Calendar Charts'},
                    {title: 'Heat Map'},
                    {title: 'Demand Duration'},
                    {title: 'Zoomable Chart'},
                ],
                colors: [
                    '#ffffff',
                    '#fafafa',
                    'yellow darken-2',
                    'red',
                    'orange',
                ],
                homeTab: 0,
            }
        },
        // watch: {
        //   explainerVideoDialog (val) {
        //     if(!val){
        //       $('.explainerVideo').each(function(){
        //         this.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*')
        //       });
        //     }
        //   }
        // },

        methods: {
            logger: function() {
                console.log("open event");
            },
            toggleSlideout: function () {
                // Toggle menu on small device
                this.$children[0].$children[0].slideout.toggle();
                this.menuOpen = !this.menuOpen
            },
            toggleMenu: function () {
                this.menuOpen = !this.menuOpen
            },
            submitContactUs: function () {
                if (this.contactUs.email && this.contactUs.message && this.contactUs.name) {

                    var self = this;

                    //submit contactus form using axios
                    Axios.post('/SendContactUsEmail', {
                        name: this.contactUs.name,
                        email: this.contactUs.email,
                        phone: this.contactUs.phone,
                        message: this.contactUs.message
                    }).then(function () {
                        self.contactUsSnackbar = {
                            message: 'Thanks for reaching out! Look for an email from us shortly.',
                            open: true,
                            color: '#4caf50'
                        }
                    });
                } else {
                    self.contactUsSnackbar = {
                        message: 'Please supply your name, email, and a message before submitting',
                        open: true,
                        color: '#f44336'
                    }
                }

            },
            next: function () {
                this.onboarding = this.onboarding + 1 === this.length
                    ? 0
                    : this.onboarding + 1
            },
            prev: function () {
                this.onboarding = this.onboarding - 1 < 0
                    ? this.length - 1
                    : this.onboarding - 1
            },
            visibilityChanged: function (isVisible, entry) {
                switch (entry.target.id) {
                    case 'dataFilterRow':
                        this.visibleProducts.dataFilter = isVisible;
                        break;
                    case 'loadCurveRow':
                        this.visibleProducts.loadCurve = isVisible;
                        break;
                    case 'heatMapRow':
                        this.visibleProducts.heatMap = isVisible;
                        break;
                    case 'summaryChartRow':
                        this.visibleProducts.summaryChart = isVisible;
                        break;
                    case 'timeSeriesRow':
                        this.visibleProducts.timeSeries = isVisible;
                        break;
                    case 'calendarChartRow':
                        this.visibleProducts.calendarChart = isVisible;
                        break;
                    case 'demandDurationRow':
                        this.visibleProducts.demandDuration = isVisible;
                        break;
                }
            },
            smoothScroll: function (target) {
                alert("hello");
                var scrollContainer = target;
                do { //find scroll container
                    scrollContainer = scrollContainer.parentNode;
                    if (!scrollContainer) return;
                    scrollContainer.scrollTop += 1;
                } while (scrollContainer.scrollTop == 0);

                var targetY = 0;
                do { //find the top of target relatively to the container
                    if (target == scrollContainer) break;
                    targetY += target.offsetTop;
                } while (target == target.offsetParent);

                scroll = function (c, a, b, i) {
                    i++;
                    if (i > 30) return;
                    c.scrollTop = a + (b - a) / 30 * i;
                    setTimeout(function () {
                        scroll(c, a, b, i);
                    }, 20);
                }
                // start scrolling
                scroll(scrollContainer, scrollContainer.scrollTop, targetY, 0);
            }
        },
        mounted() {
            console.log(this.$children[0].slideout.isOpen());
        },
        created() {
            // auth.onChange = loggedIn => {
            //   this.loggedIn = loggedIn
            // }
        }


    }


</script>


