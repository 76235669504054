<template>
    <v-container>
        <v-row class="customTitle" style="padding-bottom: 30px">
            Calendar Chart
        </v-row>
        <v-row>
            <v-container style="display: flex; justify-items: center">
                <div style="margin: auto" class="homeSection1Image">
                    <img src="../../../assets/images/screenshots/CalendarChartScreenshot2.png"
                         alt="React Energy - Interval Data Analysis"
                         style="width: 100%"
                         class="productImage"
                    />
                </div>
            </v-container>
        </v-row>
        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">
            General
        </v-row>
        <v-row class="customDescriptionText">
            The calendar chart displays load curves in a calendar type format. This tool is great for seeing how load
            differs from day to day, and occupied to unoccupied days.

        </v-row>
        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">
            Views
        </v-row>
        <v-row class="customDescriptionText">
            <ul>
                <li><span class="listTitle">Month: </span>The month view is the default view for this tool.
                </li>
                <li><span class="listTitle">Day: </span>Click on any day with data on the month view to enter into the
                    day view. Easily move forward or backwards through the individual days.
                </li>
            </ul>


        </v-row>
        <v-row class="customTitle" style="font-size: 2em; padding: 10px 0">
            Data Types
        </v-row>
        <v-row class="customDescriptionText">
            <ul>
                <li>Kilowatts (kW)</li>
            </ul>

        </v-row>
    </v-container>
</template>


<script>
    import SummaryLevels from './FilterOptions/SummaryLevels'
    import SeriesType from './FilterOptions/SeriesType'
    import Days from './FilterOptions/Days'
    import Months from './FilterOptions/Months'

    export default {
        components: {
            SummaryLevels,
            SeriesType,
            Days,
            Months
        }
    }
</script>